import { gql } from '@apollo/client'

export const UPDATE_PAYROLL_COMPANY = gql`
mutation setOrUpdatePayrollCompany($pcId: ID, $input: ISetOrUpdatePayrollCompany!) {
    setOrUpdatePayrollCompany(pcId: $pcId, input: $input) {
        pcId
        pcIdeNum
        pcDatExp
        pcDomExp
        pcState
        payrollStaff {
            psId
            psFirNam
            psSecNam
            psFirLas
            psSecLas
            psDatBir
            psPhoMob
            psPhoPer
            psEmail
            gId
            psState
            cbId
        }
    }
}
`