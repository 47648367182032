import { gql } from '@apollo/client';

export const ALL_COMPANIES = gql`
    query companies($state: [Int], $order: [[String]], $cVisible: [Int]) {
        companies(state: $state, order: $order, cVisible: $cVisible) {
            cNit
            cNitDV
            cCode
            cVisible
            cBusNam
            payrollCompaniesFN {
                dataValues {
                    totalCount
                }
            }
            averageSalary {
                totalSalary
            }
            cId
            user {
                uState
                uId
                tiId
                uIdeNum
            }
        }
    }
`;

export const ALL_INFO_CLIENT = gql`
    query getPayrollCompanyById($state: [Int]) {
        getPayrollCompanyById(state: $state) {
            pcId
            cId
            payrollStaff {
                psId
                psTerCon
                psState
            }
            payrollWork {
                pwSalary
                pwNet
            }
            payrollCompanyCategory {
                ccId
                pccState
                companyCategory {
                    ccId
                    ccState
                    companyCategoriesCreditTypes {
                        ctId
                        ccctState
                    }
                }
            }
            companyCreditTypes {
                ctId
                creditType {
                    ctId
                    ctName
                    ctMultiple
                    ctReTanking
                    ctOptPay
                    ctGroup
                }
            }
        }
        credits(state: $state, order: [["cName", "ASC"]]) {
            cId
            cName
            cValCre
            cValCou
            cBalance
            cState
            cRatVal
            cModule
            cDayCou
            creditAttributeFees {
                cafAmount
            }
        }
        groupCredits {
            gcId
            gcName
            gcMinFee
            gcMaxFee
            gcMinFeeSal
            gcMaxFeeSal
            gcMinWal
            gcMaxWal
            gcSalary
            gcSeeValReq
            gcSeeValCre
            gcSeeValFee
            gcSalPam
            gcState
            groupCreditTypes {
                gctId
                ctId
                gctOrder
                gctType
                gctIntFee
                gctState
            }
        }
    }
`;

export const UPDATE_PAYROLL_STAFF = gql`
    mutation setOrUpdatePayrollStaff($input: ISetOrUpdatePayrollStaff!) {
        setOrUpdatePayrollStaff(input: $input) {
            psId
            psTerCon
            psState
        }
    }
`;

export const USE_ONE_CREDIT_TYPE = gql`
    query getOneCreditType(
        $ctId: ID
        $state: [Int]
        $orderTypeAttribute: [[String]]
    ) {
        getCreditTypeById(ctId: $ctId, state: $state) {
            ctId
            ctName
            ctModule
            ctType
            ctCirenio
            ctMilio
            ctLyra
            ctRatVal
            ctMultiple
            mId
            month {
                mValue
            }
            rId
            rate {
                rPor
            }
            vId
            vat {
                vPor
            }
            cdId
            codebtor {
                cdName
                cdTipIde
                cdIdeNum
                cdFirNam
                cdSecNam
                cdFirLas
                cdSecLas
                cdDatBir
                cdPhoMob
                cdPhoPer
                cdEmail
                cdGender
                cdLocation
                cdAcaLev
                cdMarSta
                cdTypHou
                cdDependents
                cdDateExp
                cdPlaceExp
                cdCompany
                cdPosition
                cdTypCon
                cdSalary
                cdOthInc
                cdFinExp
                cdPerExp
                cdPerDoc
                cdFroDoc
                cdReaDoc
                cdSerDoc
                cdBanDoc
                cdLoaDoc
            }
            creditTypeAttributes {
                taId
                typeAttribute(order: $orderTypeAttribute) {
                    taId
                    taName
                    taType
                    taFormula
                    taMinimum
                    taValue
                    taValue2
                    taPor
                    tbId
                    typeBond {
                        tbId
                        bonds {
                            bPor
                        }
                    }
                    tlmId
                    typeLifeMillion {
                        tlmId
                        tlmFormula
                        lifeMillions {
                            lmSince
                            lmUntil
                            lmValue
                            lmValueTwo
                            lmPor
                        }
                    }
                    sId
                    salary {
                        sValue
                    }
                    taRates
                    taPriority
                    taVat
                }
            }
            valueCredit {
                vcId
                vcMinVal
                vcMaxVal
                vcDefVal
                mId
                month {
                    mValue
                }
            }
            ctNumCoDebtors
            ufId
            userFormat {
                ufName
                ufTipIde
                ufIdeNum
                ufDatExpDoc
                ufDomExpDoc
                ufFirNam
                ufSecNam
                ufFirLas
                ufSecLas
                ufDatBir
                ufPhoMob
                ufPhoPer
                ufEmail
                ufGender
                ufContactBy
                ufLocBir
                ufLocation
                ufAcaLev
                ufMarSta
                ufTypHou
                ufSons
                ufHeight
                ufWeight
                ufDependents
                ufPosition
                ufOthInc
                ufFinExp
                ufPerExp
                ufRefFam
                ufRefPar
                ufRefStu
                ufRelationship
                ufPerDoc
                ufFroDoc
                ufReaDoc
                ufSerDoc
                ufBanDoc
                ufConDoc
                ufPayRemDoc
                ufOPS
                ufComActDoc
                ufBudRecDoc
                ufBudCerDoc
                ufRut
                ufComChamber
                ufInstitutionPic
                ufWorkCertificate
                ufOtherInformation
                ufCirenio
                ufEconomyActivities
                ufRefStu
                ufLifeInsurance
                ufInsuranceEntities
                ufSarlaft
                ufBankStatement
                ufFinancialStatements
                ufIncomeTaxReturn
                ufBoardDirectors
                ufTradeBalance
                ufRealEstateRegistration
                ufVehicleOwnershipCard
                ufCommercialReference
                ufBankReference
                ufIncomeWithholdings
                ufCadastreAndValuation
                ufCommercialAppraisal
            }
            tfcId
            typeFormatCredit {
                tfcId
                tfcValCreBef
                tfcRatBef
                tfcAttBef
                tfcRepPlaBef
                tfcState
            }
            ctAdjustable
            ctReTanking
            ctTanPer
            ctOptPay
            ctOptPer
            ctMaxCap
            ctCard
            ctCarPer
            ctGracePeriod
            ctPayGraceInterest
            ctMinCap
        }
    }
`;

export const CALCULATOR_CREDITS = gql`
    query CalculatorCredit($input: ICalculatorCredit) {
        CalculatorCredit(input: $input) {
            ctName
            valCre
            valReq
            valCou
            month
            calculatedWithFee
            rate
            statusBond
            statusLife
            repaymentPlan {
                date
                month
                startBalance
                capital
                interest
                endBalance
            }
            credits {
                name
                value
            }
            fees {
                name
                value
            }
            origin {
                name
                value
            }
        }
    }
`;

export const ONE_SELLER = gql`
    query GetSellerById($sSellerCode: String) {
        getSellerById(sSellerCode: $sSellerCode) {
            sId
            cId
            sFirLas
            sFirNam
            sIdeNum
            sSecLas
            sSecNam
            sEmail
            sPhone
            sState
            sBusiness
            sSellerCode
        }
    }
`;

export const VERIFY_SELLER_CODE = gql`
    query VerifySellerCode($sSellerCode: String) {
        verifySellerCode(sSellerCode: $sSellerCode) {
            isSellerCodeValid
            seller {
                sId
                sIdeNum
                sFirNam
                sSecNam
                sFirLas
                sSecLas
            }
        }
    }
`;