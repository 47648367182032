import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CreditsV } from '../../components/Credits'
import { numberFormat, setData } from '../../validation'
import { useLazyQuery, useMutation } from '@apollo/client'
import { USE_ONE_CREDIT_TYPE, UPDATE_PAYROLL_STAFF, ALL_INFO_CLIENT, CALCULATOR_CREDITS, ALL_COMPANIES, VERIFY_SELLER_CODE } from './queries'
import { calculatorCreditSelect } from './calculatorCreditSelect'
import { onChangeMoney } from './onChangeMoney'
import { onChangeMonth } from './onChangeMonth'
import { handlePay } from './handlePay'
import { isLoggedVar } from '../../apollo/cache'
import moment from 'moment'

export const CreditsC = ({ navigation, route }) => {
    const [isVisibleCard, setIsVisibleCard] = useState(false)
    const [isVisibleTerm, setIsVisibleTerm] = useState(false)
    const [isVisibleCredit, setIsVisibleCredit] = useState(false)
    const [alertInfo, setAlertInfo] = useState({ show: false, title: '', message: '' })
    const [terCon, setTerCon] = useState(false)
    const [tanking, setTanking] = useState(false)
    const [card, setCard] = useState(false)
    const [multiple, setMultiple] = useState(false)
    const [optPay, setOptPay] = useState(false)
    const [terConF, setTerConF] = useState(true)
    const [getCredit, setGetCredit] = useState(true)
    const [messageErrorCard, setMessageErrorCard] = useState()
    const [messageErrorCredit, setMessageErrorCredit] = useState()
    const [payment, setPayment] = useState(0)
    const [payroll, setPayroll] = useState({})
    const [creditTypes, setCreditTypes] = useState([])
    const [cards, setCards] = useState([])
    const [selectCredits, setSelectCredits] = useState([])
    const [errorCards, setErrorCards] = useState([{}])
    const [values, setValues] = useState({})
    const [validateChangeValue, setValidateChangeValue] = useState(false)
    const [valuesCode, setValuesCode] = useState({})
    const [valuesCodeError, setValuesCodeError] = useState({})
    const [errors, setErrors] = useState({})
    const [errorForm, setErrorForm] = useState({ ctId: true })
    const [otherMount, setOtherMount] = useState()
    const [isValid, setIsValid] = useState(false);
    const [companies, setCompanies] = useState([]);
    // queries y mutaciones
    const [getInfoClient, { data: dataIC, loading: loadIC, called: calIC }] = useLazyQuery(ALL_INFO_CLIENT, { variables: { ctGroup: null }, errorPolicy: 'ignore', fetchPolicy: 'cache-and-network' })
    const [getOneCrediType, { data: dataCT, loading: loadCT, called: calledCT }] = useLazyQuery(USE_ONE_CREDIT_TYPE)
    const [SetUpdatePayrollStaff, { loading: loadTerms }] = useMutation(UPDATE_PAYROLL_STAFF)
    const [verifySellerCode, { data: dataVerifySellerCode }] = useLazyQuery(VERIFY_SELLER_CODE, {
        fetchPolicy: 'no-cache',
    });
    const [getAllCompanies, { data: allCompanies, allCompaniesCalled, allCompaniesLoading }] = useLazyQuery(ALL_COMPANIES, {
        variables: { cVisible: [1] },
        fetchPolicy: 'network-only',
    });

    //State CheckCreditDependence
    /* const [isSelected, setSelection] = useState(0)
    const changeCreditSelect = () => {
        setSelection(isSelected === 0 ? 1 : 0)
    } */

    const [getCalculator, { data: calculator, loading: loadCalculator }] = useLazyQuery(CALCULATOR_CREDITS, {
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (calculator?.CalculatorCredit?.calculatedWithFee) {
            setValidateChangeValue(true);
            setValues({ ...values, vcDefVal: Math.round(calculator?.CalculatorCredit?.valReq < 0 ? 0 : calculator?.CalculatorCredit?.valReq) })
        } else {
            if (calculator?.CalculatorCredit?.valCou !== undefined) {
                setValues({ ...values, totalFee: Math.round(calculator?.CalculatorCredit?.valCou) })
            }
        }
    }, [calculator]);
    // Activa un evento para buscar de los créditos
    useEffect(() => {
        // crea un evento para consultar de los créditos
        const unsubscribe = navigation.addListener('focus', () => {
            getInfoClient({ variables: { state: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13] } })
            setGetCredit(state => !state)
            setSelectCredits([])
            setMultiple(false)
            setTanking(false)
            setOptPay(false)
            setCard(false)
            setCards([])
            setErrorCards([{}])
            setMessageErrorCard('')
            setMessageErrorCredit('')
            if (values.ctId || !route?.params) getOneCrediType({ variables: { ctId: route.params.ctId, orderTypeAttribute: [['taPriority', 'ASC']] } })
        })

        // elimina el evento
        return unsubscribe
    }, [navigation, getInfoClient, route])
    /**
     * selecciona una linea de crédito
     * @param {object} e evento de devolución del select
     * @return {void}
     */
    const handleSelect = e => {
        if (e.value === values[e.name]) return
        // actualiza los estados
        setGetCredit(true)
        setValues({ ...values, [e.name]: e.value })
        setErrors({ ...errors, [e.name]: e.error })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setGetCredit(state => !state)
        setOptPay(false)
        setMultiple(false)
        setCard(false)
        setTanking(false)
        setSelectCredits([])
        setCards([])
        setErrorCards([{}])
        setMessageErrorCard('')
        setMessageErrorCredit('')

        // confirma si es un grupo
        if (e?.item?.gcId) navigation.navigate('GroupCredit', { ...e?.item, ...dataIC?.getPayrollCompanyById })
        // busca la información de la linea de crédito
        else getOneCrediType({ variables: { ctId: e.value, orderTypeAttribute: [['taPriority', 'ASC']] } })
    }

    const handleCalculator = value => {
        getCalculator({ variables: { input: {
            ctId: dataCT?.getCreditTypeById?.ctId,
            month: value?.months,
            vat: dataCT?.getCreditTypeById?.vat?.vPor,
            rate: dataCT?.getCreditTypeById?.ctRatVal ? dataCT?.getCreditTypeById?.ctRatVal : dataCT?.getCreditTypeById?.rate?.rPor,
            totalCard: value?.money,
            totalFee: +value?.totalFee
        } } })
    }

    // Obtiene los valores de los parametros
    useEffect(() => {
        if (route.params) handleSelect({ ...route.params, value: route.params.ctId, name: 'ctId' })
    }, [route])

    // pendiente del resultado de la búsqueda de la información del usuario
    useEffect(() => {
        if (calIC) {
            const resPC = dataIC?.getPayrollCompanyById
            const resC = dataIC?.credits
            const dataGC = dataIC?.groupCredits
            const resPCC = resPC?.payrollCompanyCategory?.pccState === 1 && resPC?.payrollCompanyCategory
            const resCCT = resPC?.companyCreditTypes

            if (resPC) {
                let payCou = 0
                resC?.forEach(x => x.cState >= 2 && (payCou += x.cValCou))
                setPayment(payCou)
                setValues({ ...values, psId: resPC?.payrollStaff?.psId })
                setPayroll(resPC)
            }

            // anterior
            if (resCCT) {
                // busca las lineas de créditos activas
                const filterState = resCCT.filter(x => x.creditType?.ctId && x.creditType?.ctGroup !== 1) || []
                const arrayCCCT = resPCC?.companyCategory?.ccState === 1 ? resPCC?.companyCategory?.companyCategoriesCreditTypes : []

                // busca si uno de las lineas de créditos son multiples y si el usuario ya lo solicito una ves
                const resFilter = filterState.filter(x => {
                    // verifica si tiene lineas de créditos personalizadas
                    if (arrayCCCT?.length) {
                        // busca si existe la linea de crédito
                        const findCCCT = arrayCCCT.find(y => y.ctId === x.creditType?.ctId)
                        if (findCCCT) {
                            if (x.creditType?.ctMultiple === 1 && (x.creditType?.ctReTanking !== 1 || x.creditType?.ctOptPay !== 1)) return !resC?.find(y => y.cName === x.creditType?.ctName && y.cState !== 1 && y.cState !== 0 && y.cState !== 6)
                            return true
                        }
                        return false
                    } else {
                        if (x.creditType?.ctMultiple === 1 && (x.creditType?.ctReTanking !== 1 || x.creditType?.ctOptPay !== 1)) return !resC?.find(y => y.cName === x.creditType?.ctName && y.cState !== 1 && y.cState !== 0 && y.cState !== 6)
                        return true
                    }
                })

                // manda una alerta por si no hay ninguna linea de crédito disponible
                if (resFilter?.length) {
                    // verifica si existen grupos
                    for (let i = 0; i < dataGC?.length; i++) {
                        const { groupCreditTypes, ...rest } = dataGC[i]
                        let validationGroup = true

                        // recorre las líneas de crédito del grupo
                        for (let ii = 0; ii < groupCreditTypes?.length; ii++) {
                            const { ctId } = groupCreditTypes[ii]
                            if (validationGroup) validationGroup = !!resCCT.find(x => x.ctId === ctId)
                        }

                        // agrega el grupo para ser usado
                        if (validationGroup) resFilter.push({ creditType: { ctId: rest.gcId, ctName: rest.gcName, ...rest, groupCreditTypes } })
                    }

                    if (!values.ctId) handleSelect({ error: false, name: 'ctId', value: resFilter[0].ctId })
                } else isLoggedVar({ ...isLoggedVar(), message: 'No se ha encontrado ninguna linea de crédito disponible.' })

                // actualiza el estado
                setCreditTypes(resFilter.map(x => x.creditType))
            }

            // verifica si hay error
            if (!resCCT?.length && resPC?.pcId) {
                setCreditTypes([])
                setValues({ ...values, ctId: undefined, vcDefVal: 0, vcValMin: 0, vcMaxVal: 0, mMinVal: 0, mMaxVal: 0, mDefVal: 0, valCre: 0, valCou: 0 })
                return isLoggedVar({ ...isLoggedVar(), message: 'No se ha encontrado ninguna linea de crédito disponible.' })
            }
        }
    }, [dataIC, loadIC, calIC])

    // pendiente del resultado de la búsqueda de una linea de crédito
    useEffect(() => {
        const res = dataCT?.getCreditTypeById
        if (calledCT && res) {
            // calcula los valores del crédito seleccionado
            const resultCCS = calculatorCreditSelect({ payment, params: res || {}, payroll: payroll || {}, credits: dataIC?.credits })
            // verifica que si hay un error
            if (resultCCS.error) {
                setValues({ ...values, ...resultCCS.data, valCou: 0, vcDefVal: 0, mDefVal: 0, errorMessage : resultCCS?.message || '' })
                setErrorForm({ ctId: true })
                setCreditTypes(state => state.filter(x => x.ctId !== values.ctId))
                isLoggedVar({ ...isLoggedVar(), message: resultCCS.message })
            } else {
                // activa el modo normal si es permitido el mismo crédito
                if (res.ctMultiple === 2) setMultiple(true)

                // verifica el retanqueo del mismo crédito
                if (res.ctReTanking === 1) {
                    // busca si existe un crédito en solicitud
                    const findCreditRequest = dataIC?.credits?.find(x => x.cName === res.ctName && x.cState >= 2 && x.cState <= 5)
                    if (!findCreditRequest) {
                        // busca los créditos activos
                        const findCredit = dataIC?.credits?.find(x => x.cName === res.ctName && x.cState === 6)

                        // verifica si se encontró un crédito
                        if (findCredit) setTanking(true)
                    }
                }

                // verifica el retanqueo de diferentes crédito
                if (res.ctOptPay === 1) {
                    // busca los créditos activos
                    const findCredit = dataIC?.credits?.find(x => x.cState === 6)

                    // verifica si se encontró un crédito
                    if (findCredit) setOptPay(true)
                }

                // verifica si el modo cartera esta habilitado
                if (res.ctCard === 1) setCard(true)
                setValues({ ...values, ...dataCT?.getCreditTypeById, ...resultCCS.data, typeFormatCredit: res.typeFormatCredit, rPor: res.rate?.rPor, errorMessage : resultCCS?.message || '' })
            }
        }
    }, [dataCT, calledCT, getCredit])

    /**
     * Solicita el crédito
     * @version 0.0.1
     * @param {boolean} terms permiso de términos y condiciones aprobado
     * @returns {void} cambia de ventana
     */
    const handleReqCre = async terms => {
        const { vcDefVal, valCou, ctId, mDefVal, dateEnd, permission } = values
        const { payrollWork, payrollStaff } = payroll
        const { ctName, ctTanPer, ctOptPer } = dataCT?.getCreditTypeById
        let conTanking = false
        let conOptPay = false
        let tankingCredits = []
        setErrors(errorForm)
        const moneyAvailable = Math.round((payrollWork?.pwNet / 2) - payment)
        // verifica si el valor de la cuota es valido o el solicitado es valido
        if (vcDefVal < 10000 && valCou < 10000) return isLoggedVar({ ...isLoggedVar(), message: 'No es posible solicitar un crédito donde el monto es menor a $ 10.000 COP.' })

        /** compara si existe una línea de crédito */
        if (!ctId) return isLoggedVar({ ...isLoggedVar(), message: 'Es necesario seleccionar una línea de crédito.' })

        /** compara si selecciona una pagaduria cuando es necesario*/
        // if (companies?.length > 0 && !valuesCode?.cId) return isLoggedVar({ ...isLoggedVar(), message: 'Es necesario seleccionar una pagaduría' })

        // verifica las opciones del retanqueo
        if (tanking || optPay) {
            let valTanking = vcDefVal
            let payCouTan = payment

            // verifica que el monto de desembolso sea suficiente para pagar
            dataIC?.credits?.forEach(x => {
                if (x.cState === 6) {
                    // obtiene el total de dinero de los atributos
                    let valAttTot = 0
                    x.creditAttributeFees.forEach(y => valAttTot += y.cafAmount)

                    // calcula el interés
                    const valTotCre = x.cBalance

                    // verifica si es retanqueo o refinanciación
                    if (valTanking >= valTotCre && ctName === x.cName && valTotCre <= ((ctTanPer / 100) * x.cValCre) && tanking) {
                        conTanking = true
                        valTanking -= valTotCre
                        payCouTan -= x.cValCou
                        tankingCredits = [...tankingCredits, { cId: x.cId }]
                    }
                    if (vcDefVal >= valTotCre && ctName !== x.cName && valTotCre <= ((ctOptPer / 100) * x.cValCre) && optPay) conOptPay = true
                }
            })

            // verifica que el monto de la cuota disponible alcance a pagar
            if (((payrollWork?.pwNet || 0) / 2) <= (payCouTan + valCou) && !permission) conTanking = false
        }

        /** verifica si tiene capacidad */
        if (!conTanking && !conOptPay && ((payrollWork?.pwNet / 2) - payment) < valCou && !permission) return isLoggedVar({ ...isLoggedVar(), message: moneyAvailable > 50000 ? `Su cuota no puede ser mayor a $ ${numberFormat(moneyAvailable)}.` : 'No tiene cupo disponible para solicitar un crédito.' })

        /** notifica que no hay créditos multiples en la línea de crédito */
        if (!multiple && !conTanking && !conOptPay && dataIC?.credits?.find(x => x?.cState !== 1 && x?.cState !== 0 && x?.cState !== 6 && x.cName === ctName)) return isLoggedVar({ ...isLoggedVar(), message: 'Ya tiene un crédito activo, por lo que no es posible solicitar otro hasta finalizar el crédito.' })

        /** verifica si ya se acepto los términos y condiciones */
        if (!terms && (!terConF || !payrollStaff.psTerCon)) return setIsVisibleTerm(true)

        /** activa el modo refinanciación */
        if (!conTanking && conOptPay && !selectCredits?.length && dataIC?.credits?.length) {
            setSelectCredits(dataIC?.credits?.filter(x => x.cName !== ctName && x.cState === 6 && x.cBalance <= ((ctOptPer / 100) * x.cValCre)) || [])
            return setAlertInfo({ show: true, title: 'Refinanciación', message: '¿Desea refinanciar otros créditos?', optPay: true })
        }

        // verifica si se esta solicitando modo cartera
        if (!cards?.length && card) {
            setCards([{ index: 0 }])
            return setAlertInfo({ show: true, title: 'Cartera', message: '¿Desea comprar cartera con esta solicitud de crédito?', card: true, tanking: conTanking })
        }
        // guarda la información de solicitud del crédito
        setData('infCredit', JSON.stringify({
            cMonVal: mDefVal,
            cReqVal: Math.round(calculator?.CalculatorCredit?.valReq),
            ctId,
            cId: valuesCode?.cId,
            dateEnd,
            cpsSellerCode: valuesCode?.sSellerCode?.length > 0 ? valuesCode?.sSellerCode : null,
            cReTanking: conTanking ? 1 : 0,
            cOptPay: (!conTanking && conOptPay && (conTanking ? tankingCredits : selectCredits.filter(x => x.check)?.length)) ? 1 : 0,
            cCard: cards?.filter(x => x.ccBalance)?.length ? 1 : 0,
            creditsOptPays: conTanking ? tankingCredits : (selectCredits.filter(x => x.check)?.map(x => ({ cId: x.cId })) || []),
            creditCards: card ? cards.map(x => ({ ccName: x.ccName, ccFee: x.ccFee, ccBalance: x.ccBalance })) : []
        }))

        // envía a la solicitud de la información con los requerimientos
        setGetCredit(false)
        setValues({ ...values, ctId: undefined, vcDefVal: 0, vcValMin: 0, vcMaxVal: 0, mMinVal: 0, mMaxVal: 0, mDefVal: 0, valCre: 0, valCou: 0 })

        navigation.navigate('RequestCreditData', {
            payment,
            userFormat: dataCT?.getCreditTypeById?.userFormat,
            codebtor: dataCT?.getCreditTypeById?.codebtor,
            number: dataCT?.getCreditTypeById?.ctNumCoDebtors,
            ctCirenio: dataCT?.getCreditTypeById?.ctCirenio,
            ctMilio: dataCT?.getCreditTypeById?.ctMilio,
            ctLyra: dataCT?.getCreditTypeById?.ctLyra,
            cReTanking: conTanking ? 1 : 0,
            cOptPay: (!conTanking && conOptPay && (conTanking ? tankingCredits : selectCredits.filter(x => x.check)?.length)) ? 1 : 0,
            cCard: cards?.filter(x => x.ccBalance)?.length ? 1 : 0,
            creditCards: cards?.filter(x => x.ccBalance)?.length ? cards.map(x => ({ ccName: x.ccName, ccFee: x.ccFee, ccBalance: x.ccBalance })) : [],
            creditsOptPays: conTanking ? tankingCredits : (selectCredits.filter(x => x.check)?.map(x => ({ cId: x.cId })) || []),
            credits: dataIC?.credits || [],
            permission
        })
    }

    /**
     * aceptar términos y condiciones
     * @version 0.0.1
     * @returns {void} actualiza el estado
     */
    const handleConfirmCheck = async () => {
        /** variables necesarias */
        setTerConF(terCon)

        if (terCon) {
            /** acepta los términos y condiciones */
            const { data, error } = await SetUpdatePayrollStaff({ variables: { input: { psId: payroll?.payrollStaff?.psId, psTerCon: 1, psState: 1 } } }).catch(e => ({ error: e }))

            // verifica si hay error
            if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message })

            // verifica la respuesta
            if (data?.setOrUpdatePayrollStaff) {
                setPayroll({ ...payroll, payrollStaff: { ...payroll.payrollStaff, psTerCon: 1 } })
                handleReqCre(true)
            }
            setIsVisibleTerm(!isVisibleTerm)
        }
    }

    /**
     * Agrega o elimina un registro a la cartera
     * @version 0.0.1
     * @param {number} type tipo de ejecución si eliminar o agregar un registro
     * @param {number} index indice del array
     * @returns {void}
     */
    const handleIcon = (type, index) => {
        // agrega un registro al array
        if (type) {
            setCards([...cards, { index: index + 1 }])
            setErrorCards([...errorCards, {}])
        } else { // elimina un registro al array
            setCards(cards.filter((x, i) => i !== index) || [{}])
            setErrorCards(errorCards.filter((x, i) => i !== index) || [{}])
        }
    }

    /**
     * captura los valores de los inputs de la cartera
     * @version 0.0.1
     * @param {object} e evento del input
     * @param {number} index indice del array
     * @returns {void}
     */
    const onChangeCard = (e, index) => {
        setCards(cards.map((x, i) => i === index ? { ...x, [e.name]: e.value } : x))
        setErrorCards(errorCards.map((x, i) => i === index ? { ...x, [e.name]: e.error } : x))
    }

    /**
     * captura la confirmación de la cartera
     * @version 0.0.1
     * @param {object} e evento del input
     * @param {number} index indice del array
     * @returns {void}
     */
    const handleConfirmCard = () => {
        const { ctCarPer, ctTanPer, ctOptPer, ctName } = dataCT?.getCreditTypeById
        const { vcDefVal } = values
        let valTanking = vcDefVal
        let newErrors = []
        let errorConfirm = false
        let totalCard = 0
        let totCreOpt = 0
        const perCar = ctCarPer / 100 || 1

        for (let i = 0; i < errorCards.length; i++) {
            const { ccName, ccFee, ccBalance } = errorCards[i]

            // registra los nuevos valores de error
            newErrors = [...newErrors, { ccName: ccName === undefined || ccName, ccFee: ccFee === undefined || ccFee, ccBalance: ccBalance === undefined || ccBalance }]

            // verifica si hay un error en el formulario
            if ((ccName === undefined || ccName) || (ccFee === undefined || ccFee) || (ccBalance === undefined || ccBalance)) errorConfirm = true
        }

        // recorre el las cards para ver el total del monto de cartera
        cards.forEach(x => (totalCard += x.ccBalance))

        // verifica si es retanqueo
        if (alertInfo.tanking) {
            // verifica que el monto de desembolso sea suficiente para pagar
            dataIC?.credits?.forEach(x => {
                // obtiene el total de dinero de los atributos
                let valAttTot = 0
                x.creditAttributeFees.forEach(y => valAttTot += y.cafAmount)

                // calcula el interés
                const valTotCre = x.cBalance

                // verifica si cumple las características
                if (valTanking >= valTotCre && x.cState === 6 && ctName === x.cName && valTotCre <= ((ctTanPer / 100) * x.cValCre)) {
                    valTanking -= valTotCre
                    totCreOpt += x.cBalance
                }
            })
        }

        // verifica si es refinanciación
        if (alertInfo.optPay) {
            // verifica que el monto de desembolso sea suficiente para pagar
            selectCredits?.forEach(x => {
                if (x.check) {
                    // obtiene el total de dinero de los atributos
                    let valAttTot = 0
                    x.creditAttributeFees.forEach(y => valAttTot += y.cafAmount)

                    // calcula el interés
                    const interest = Math.round(x.cBalance * ((x.cRatVal / 100) * (x.cModule === 1 ? (x.cDayCou / 30) : ((Number(moment().format('DD')) + 1) / 30))))
                    const valTotCre = x.cBalance + interest + valAttTot

                    // verifica si cumple las características
                    if (vcDefVal >= valTotCre && x.cState === 6 && valTotCre <= ((ctOptPer / 100) * x.cValCre)) {
                        totCreOpt += x.cBalance
                        valTanking -= valTotCre
                    }

                }
            })
        }

        // verifica si hubo un error
        if (errorConfirm) {
            setErrorCards(newErrors)
            return setMessageErrorCard('Por favor rellene todo los campos')
        }

        // verifica que el monto de las card no sea superior al permitido
        if ((totalCard + totCreOpt) > (perCar * values.vcDefVal)) return setMessageErrorCard(`El monto de la cartera no puede ser mayor a: ${numberFormat(Math.round(perCar * values.vcDefVal) - totCreOpt)}`)

        setIsVisibleCard(false)
        setTimeout(() => handleReqCre(true), 500)
    }

    /**
     * Confirmar o cancelar alerta
     * @version 0.0.1
     * @param {number} type tipo de acción en la alerta
     * @returns {void}
     */
    const handleAlert = type => {
        if (type) {
            setAlertInfo({ ...alertInfo, show: false })
            if (alertInfo.group) navigation.navigate('GroupCredit')
            else {
                setTimeout(() => {
                    if (alertInfo.optPay) setIsVisibleCredit(true)
                    else if (alertInfo.card) setIsVisibleCard(true)
                }, 500)
            }
        } else {
            setAlertInfo({ ...alertInfo, show: false })
            if (alertInfo.group) return false
            else if (alertInfo.optPay) setOptPay(false)
            else if (alertInfo.card) setCard(false)

            setTimeout(() => handleReqCre(true), 500)
        }
    }

    /**
     * Confirma los créditos seleccionado
     * @version 0.0.1
     * @returns {void}
     */
    const handleConfirmCredit = () => {
        const { vcDefVal, valCou } = values
        const { payrollWork } = payroll
        const { ctOptPer } = dataCT?.getCreditTypeById
        let credit = false
        let valTanking = vcDefVal
        let payCouTan = payment

        // verifica que el monto de desembolso sea suficiente para pagar
        selectCredits?.forEach(x => {
            if (x.check) {
                // obtiene el total de dinero de los atributos
                let valAttTot = 0
                x.creditAttributeFees.forEach(y => valAttTot += y.cafAmount)

                // calcula el interés
                const valTotCre = x.cBalance

                // verifica si es refinanciación
                if (valTanking >= valTotCre && x.cState === 6 && valTotCre <= ((ctOptPer / 100) * x.cValCre) && optPay) {
                    valTanking -= valTotCre
                    payCouTan -= x.cValCou
                    credit = true
                }
            }
        })

        // verifica si no hay ningún crédito disponible
        if (!dataIC?.credits?.length) {
            isLoggedVar({ ...isLoggedVar(), message: 'No tiene créditos para refinanciación' })
            setIsVisibleCredit(false)
            setOptPay(false)
            return
        }

        // verifica que seleccione un crédito
        if (!credit) return setMessageErrorCredit('Es necesario seleccionar un crédito para la refinanciación.')

        // verifica que el monto de la cuota disponible alcance a pagar
        if (((payrollWork?.pwNet || 0) / 2) <= (payCouTan + valCou)) return setMessageErrorCredit(`El monto disponible con el crédito seleccionado es de: ${numberFormat(Math.round(payCouTan + valCou))}, es mayor que su cupo disponible que es de: ${((payrollWork?.pwNet || 0) / 2)}.`)

        /** compara si su valor de disponibilidad es mayor a la cuota */
        setIsVisibleCredit(false)
        setTimeout(() => handleReqCre(true), 500)
    }

    const inputChange = money => {
        const { value, name } = money

        if (name === 'totalFee') {
            setValues({ ...values, totalFee: value })
            return;
        }

        const moneyData = onChangeMoney({ params: dataCT?.getCreditTypeById || {}, values, money: value })
        setValues({ ...values, ...moneyData })
    }

    useEffect(() => {
        if (allCompanies && !allCompaniesLoading) {
            const res = allCompanies?.companies
            setCompanies(res || [])
        }

    }, [allCompanies, allCompaniesCalled, allCompaniesLoading])

    useEffect(() => {
        if (valuesCode?.idCode === 1){
            setIsValid(true);
        } else if (valuesCode?.idCode === 0){
            setIsValid(false);
            setValuesCode({})
        }

    }, [valuesCode])

    useEffect(() => {
        setValuesCode({})
        setValuesCodeError({})
        getAllCompanies()
    }, [])

    useEffect(() => {
        if (dataVerifySellerCode) {
            if (!dataVerifySellerCode?.verifySellerCode?.isSellerCodeValid) {
                isLoggedVar({ ...isLoggedVar(), message: 'Lo sentimos, el código de vendedor ingresado no existe en nuestro sistema.' })

                setValuesCodeError({ ...valuesCodeError, sSellerCode: 'Código de vendedor ingresado no existe' })

                setIsValid(true);

                return;
            }

            isLoggedVar({ ...isLoggedVar(), message: 'Código de vendedor correcto.' })

            setIsValid(false);
        }

    }, [dataVerifySellerCode])

    const handleBlur = async e => {
        const { value } = e;
        if (value?.length > 0){
            await verifySellerCode({ variables: { sSellerCode: value } });
        } else {
            isLoggedVar({ ...isLoggedVar(), message: 'Ingrese código de vendedor.' })
        }

    }
    const handleChange = e => {
        setValuesCode({ ...valuesCode, [e.name]: e.value })
        setValuesCodeError({ ...valuesCodeError, [e.name]: e.error })
    }
    return (
        <CreditsV
            data={{
                cards,
                companies,
                values,
                loadCT,
                errors,
                terCon,
                terConF,
                alertInfo,
                loadTerms,
                errorCards,
                navigation,
                creditTypes,
                isVisibleCard,
                isVisibleTerm,
                isVisibleCredit,
                messageErrorCard,
                messageErrorCredit,
                loadRequest: false,
                credits: selectCredits,
                loading: !payroll?.pcId && loadIC,
                otherMount,
                valuesCode,
                isValid,
                valuesCodeError,
                loadCalculator,
                calculator
            }}
            validateChangeValue={validateChangeValue}
            handleIcon={handleIcon}
            handleAlert={handleAlert}
            handleSelect={handleSelect}
            onChangeCard={onChangeCard}
            requestCredit={() => handleReqCre()}
            handleConfirmCard={handleConfirmCard}
            handleCheck={() => setTerCon(!terCon)}
            handleConfirmCheck={handleConfirmCheck}
            handleConfirmCredit={handleConfirmCredit}
            handleCloseCard={() => setIsVisibleCard(false)}
            handleDetailModal={() => navigation.navigate('CreditTypeRepaymentPlan', { ...values, calculator })}
            handleCloseCredit={() => { setIsVisibleCredit(false); setSelectCredits([]) }}
            handleCheckCredit={item => setSelectCredits(selectCredits.map(x => x.cId === item.cId ? { ...x, check: !x.check } : x))}
            handlePay={v => setValues({ ...values, ...handlePay({ params: dataCT?.getCreditTypeById || {}, values, valueSelect: v }) })}
            onChangeMoney={money => setValues({ ...values, ...onChangeMoney({ params: dataCT?.getCreditTypeById || {}, values, money }) })}
            onChangeMonth={month => setValues({ ...values, ...onChangeMonth({ params: dataCT?.getCreditTypeById || {}, values, month }) })}
            /* isSelected={isSelected}
            changeCreditSelect={changeCreditSelect} */
            onChangeTextCredit={e => setValues({ ...values, [e.name]: e.value })}
            setOtherMount={() => setOtherMount(!otherMount)}
            inputChange={inputChange}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleCalculator={handleCalculator}
        />
    )
}

CreditsC.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
}