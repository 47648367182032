import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { USE_ALL_CONTACTS } from '../../queries/ContactBy'
import { SelectHook } from '../SelectHook'

export const Contacts = ({ value, handleSelect, handleCancel, use = {}, disabled, error }) => {
    const [res, setRes] = useState([])
    const { data, loading } = USE_ALL_CONTACTS({ order: [['cbName', 'ASC']], ...use })

    useEffect(() => {
        if (data?.contactBy && !loading) setRes(data?.contactBy || [])
    }, [data])

    return (
        <SelectHook
            data={res}
            error={error}
            loading={loading}
            title='Contacto preferido *'
            value={value}
            name='cbId'
            nameAtt='cbName'
            titleModal='Seleccionar contacto preferido'
            disabled={disabled}
            handleSelect={handleSelect}
            handleCancel={handleCancel}
        />
    )
}

Contacts.propTypes = {
    value: PropTypes.string,
    handleSelect: PropTypes.func,
    handleCancel: PropTypes.func,
    use: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool
}