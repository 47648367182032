import React from 'react'
import PropTypes from 'prop-types'
import { InputHook } from '../InputHook'
import { TypeIdentities } from '../Selects/TypeIdentities'
import { Genders } from '../Selects/Genders'
import { ButtonSubmit } from '../ButtonSubmit'
import { InputDateWeb } from '../InputDateWeb'
import { Container, Form, Row, BoxButton } from './styled'
import { Cities } from '../Selects/Cities'
import { Contacts } from '../Selects/ContactBy'

export const PersonalDataRegisterV = ({ data: { banner, values, errors, format, loading }, handleBlur, handleChange, handleChangeStaff, handleSubmit }) => (
    <Container>
        {banner}
        <Form>
            <TypeIdentities value={values?.tiId} disabled />
            <Row>
                <InputHook flex={format?.ufDatExpDoc ? .47 : 1} title='Número de identificación' value={values?.pcIdeNum} editable={false} />
                {!!format?.ufDatExpDoc && <InputDateWeb testID='327aa03c-6426-47b5-b7b6-faab1e981e19' flex={.47} title='Fecha de Expedición *' name='pcDatExp' error={errors?.pcDatExp} value={values?.pcDatExp} onChangeText={handleChange} />}
            </Row>
            {!!format?.ufDomExpDoc && <Cities testID='0f18e890-9471-11ee-9202-23123419631f' title='Lugar de expedición *' error={errors?.cName} value={values?.cName} name='cName' nameAtt='cName' handleSelect={handleChange} />}
            <Row>
                <InputHook flex={.47} title='Primer Nombre *' name='psFirNam' error={errors?.psFirNam} value={values?.payrollStaff?.psFirNam} letters required range={[2, 30]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />
                <InputHook flex={.47} title='Segundo Nombre' name='psSecNam' error={errors?.psSecNam} value={values?.payrollStaff?.psSecNam} letters range={[2, 30]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />
            </Row>
            <Row>
                <InputHook flex={.47} title='Primer Apellido *' name='psFirLas' error={errors?.psFirLas} value={values?.payrollStaff?.psFirLas} letters required range={[2, 30]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />
                <InputHook flex={.47} title='Segundo Apellido' name='psSecLas' error={errors?.psSecLas} value={values?.payrollStaff?.psSecLas} letters range={[2, 30]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />
            </Row>
            <Row>
                {!!format?.ufDatBir && <InputDateWeb testID='d3c10cee-a225-451b-a9a8-fd9ec1f74c0f' flex={format?.ufPhoPer ? .47 : 1} title='Fecha de Nacimiento *' name='psDatBir' less error={errors?.psDatBir} value={values?.payrollStaff?.psDatBir} onChangeText={e => handleChangeStaff(e, 1)} />}
                {!!format?.ufPhoPer && <InputHook testID='67418a3b-3022-4a63-b83d-d584ce82f3e6' flex={format?.ufDatBir ? .47 : 1} title='Número Teléfono Fijo' returnKeyType='next' keyboardType='numeric' error={errors?.psPhoPer} value={values?.payrollStaff?.psPhoPer} name='psPhoPer' numeric range={[5, 15]} onBlur={handleBlur} onChangeText={e => handleChangeStaff(e, 1)} />}
            </Row>
            {!!format?.ufLocBir && <Cities testID='8aac29f3-413f-4d59-9736-618c52bac7cq' title='Lugar de Nacimiento' error={errors?.cId} value={values?.payrollStaff?.cId} handleSelect={e => handleChangeStaff(e, 1)} />}
            {!!format?.ufGender && <Genders testID='8aac29f3-413f-4d59-9736-618c52bacc9c' error={errors?.gId} value={values?.payrollStaff?.gId} handleSelect={e => handleChangeStaff(e, 1)} />}
            {!!format?.ufContactBy && <Contacts error={errors?.cbId} value={values?.payrollStaff?.cbId} handleSelect={e => handleChangeStaff(e, 1)} />}
            <BoxButton>
                <ButtonSubmit testID='4002c70a-d205-4c09-8266-504c02398eda' onPress={handleSubmit} title='Continuar' loading={loading} />
            </BoxButton>
        </Form>
    </Container>
)

PersonalDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleChangeStaff: PropTypes.func,
    handleSubmit: PropTypes.func,
}