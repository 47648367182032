import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CreditTypeRepaymentPlanV } from '../../components/CreditTypeRepaymentPlan'
import { calculateCreditCard } from '../GroupCredit/calculateCreditCard'

export const CreditTypeRepaymentPlanC = ({ route }) => {
    const [credit, setCredit] = useState({})
    const [see, setSee] = useState(false)
    const [table, setTable] = useState(true)

    useEffect(() => {
        const res = route.params
        if (res) {
            const { fees, credits: cres, valCre, repaymentPlan } = calculateCreditCard({ month: { mValue: res.mDefVal }, vat: res.vat, rate: res.rate || res?.ctRatVal, creditTypeAttributes: res.creditTypeAttributes, totalCard: res.vcDefVal })

            setCredit({
                ctModule: res.ctModule,
                ctName: res.ctName,
                vcDefVal: res.vcDefVal,
                rPor: res?.ctRatVal ? res?.ctRatVal : res.rate?.rPor,
                valCre,
                valCou: res.valCou,
                por: res?.ctRatVal ? res?.ctRatVal : res.rate?.rPor,
                fees,
                cres,
                mDefVal: res.mDefVal,
                repaymentPlan,
                typeFormatCredit: res.typeFormatCredit,
                calculator: res.calculator
            })
        }
    }, [route])

    return (
        <CreditTypeRepaymentPlanV
            data={{ credit, table, see }}
            handleOpen={() => setSee(!see)}
            changeTable={() => setTable(!table)}
        />
    )
}

CreditTypeRepaymentPlanC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object
}