import React from 'react'
import PropTypes from 'prop-types'
import { BoxInput, Label, styles } from './styled'
import { mq, numberFormat } from '../../validation'
import Slider from '@react-native-community/slider'
import { InputHook } from '../InputHook'

export const ViewSlider = ({ values, onChangeMoney, theme, handleCalculator }) => (
    <>
        <BoxInput>
            <Label
                fFamily='PFontMedium'
                color={theme.SFSColor}
                size={mq(13)}
            >
                $ {numberFormat(values.vcDefVal >= 0 ? values.vcDefVal : 500000)} COP
            </Label>
        </BoxInput>
        <Slider
            onSlidingComplete={e => handleCalculator({ months: values?.mDefVal, money: e })}
            step={10000}
            style={styles.slider}
            onValueChange={onChangeMoney}
            thumbTintColor={theme.PColor}
            value={values.vcDefVal || 500000}
            minimumTrackTintColor={theme.PColor}
            maximumTrackTintColor={theme.SFVColor}
            maximumValue={values.ctMaxCap || 1000000}
            testID='cdbcf5e2-a9d8-47dc-acab-5d96db77f1f8'
            minimumValue={values.ctMinCap >= 0 ? values.ctMinCap : 1}
        />
    </>
)

ViewSlider.propTypes = {
    values: PropTypes.object.isRequired,
    onChangeMoney: PropTypes.func.isRequired,
    theme: PropTypes.any,
    handleCalculator: PropTypes.func,
}

export const ViewInput = ({ values, onChangeMoney, theme, handleCalculator, validateChangeValue }) => (
    <>
        <BoxInput>
            <Label
                fFamily='PFontMedium'
                color={theme.SFSColor}
                size={mq(13)}
            >
                $ {numberFormat(values.vcDefVal >= 0 ? values.vcDefVal : 500000)} COP
            </Label>
        </BoxInput>
        <InputHook title='Monto a Solicitar' keyboardType='numeric' value={values?.vcDefVal} onBlur={() => handleCalculator({ months: values?.mDefVal, money: values?.vcDefVal })} name='vcDefVal' maximum={values.ctMaxCap || 1000000} minimum={values.ctMinCap >= 0 ? values.ctMinCap : 1} numeric format range={[1, 15]} onChangeText={onChangeMoney} validateChangeValue={validateChangeValue} />
    </>
)

ViewInput.propTypes = {
    values: PropTypes.object.isRequired,
    onChangeMoney: PropTypes.func.isRequired,
    theme: PropTypes.any,
    validateChangeValue: PropTypes.bool,
    handleCalculator: PropTypes.func,
}

export const CustomViewInput = ({
    value,
    onChangeMoney,
    title,
    keyboardType,
    name,
    maximum,
    minimum,
    theme,
    numeric,
    format,
    range,
    customOnBlur,
}) => (
    <>
        <BoxInput>
            <Label
                fFamily='PFontMedium'
                color={theme.SFSColor}
                size={mq(13)}
            >
                $ {numberFormat(value || 0)} COP
            </Label>
        </BoxInput>
        <InputHook
            title={title || ''}
            keyboardType={keyboardType || 'numeric'}
            value={value}
            onBlur={customOnBlur}// onBlur={() => handleCalculator({ months: value?.mDefVal, money: value?.vcDefVal })}
            name={name}
            maximum={maximum || 1000000} // maximum={value.ctMaxCap || 1000000}
            minimum={minimum || 1} // minimum={value.ctMinCap >= 0 ? value.ctMinCap : 1}
            numeric={numeric}
            format={format}
            range={range || [1, 15]}
            onChangeText={onChangeMoney} />
    </>
)

CustomViewInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChangeMoney: PropTypes.func.isRequired,
    title: PropTypes.string,
    keyboardType: PropTypes.string,
    name: PropTypes.string.isRequired,
    maximum: PropTypes.bool,
    minimum: PropTypes.bool,
    numeric: PropTypes.bool,
    format: PropTypes.bool,
    range: PropTypes.array,
    theme: PropTypes.any,
    customOnBlur: PropTypes.func,
}