import { gql, useQuery } from '@apollo/client'

export const USE_ALL_CONTACTS = ({ state, order, limit }) => useQuery(gql`
    query ContactBy($state: [Int], $order: [[String]], $limit: [Int]) {
        contactBy(state: $state, order: $order, limit: $limit) {
            cbId
            cbName
            cbState
        }
    }
`, {
    variables: { state, order, limit }
})