// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView, /* , CheckBox */ Text } from 'react-native'
import Slider from '@react-native-community/slider'
import { useTheme } from 'styled-components/native'
import { AntDesign, MaterialIcons } from '@expo/vector-icons'

// Components
import { Alert } from './Alert'
import { Cards } from './Cards'
import { Modal } from '../Modal'
import { Credits } from './Credits'
import { ButtonNew } from '../Home/styled'
import { ModalTerms } from '../ModalTerms'
import { SelectHook } from '../SelectHook'
import { ButtonSubmit } from '../ButtonSubmit'
import { CreditRequestS, CreditRequestInfoS } from '../../skeleton/CreditRequestS'

// Styles and Utils
import { ShadowBox } from '../../generalStyles'
import { numberFormat, mq } from '../../validation'
import { Container, Box, BoxIcon, BoxDescription, HeaderContainer, Title, Body, Label, BoxInput, BoxInfo, Center, TextFooter, TextFee, styles, HeadCard, Button, /* CheckCredit, CheckInputBox, */ ButtonMount, BoxContainer, BoxContainerInputHook } from './styled'
/* import { InputHook } from '../InputHook' */
import { useNavigation } from '@react-navigation/native'
import { CustomViewInput, ViewInput, ViewSlider } from './amountSelects'
import { InputHook } from '../InputHook'

/**
 * Credits View Component
 * @param {any} props Properties Credits View
 * @returns {ReactElement} CreditsV Component
 */
export const CreditsV = (
    {
        data: {
            cards,
            errors,
            loadCT,
            values,
            terCon,
            loading,
            companies,
            terConF,
            credits,
            loadTerms,
            alertInfo,
            errorCards,
            creditTypes,
            loadRequest,
            isVisibleTerm,
            isVisibleCard,
            isVisibleCredit,
            messageErrorCard,
            messageErrorCredit,
            otherMount,
            valuesCode,
            isValid,
            valuesCodeError,
            calculator,
            loadCalculator
        },
        handlePay,
        handleIcon,
        handleCheck,
        handleAlert,
        handleSelect,
        onChangeCard,
        requestCredit,
        validateChangeValue,
        onChangeMoney,
        onChangeMonth,
        handleCloseCard,
        handleDetailModal,
        handleCheckCredit,
        handleConfirmCard,
        handleCloseCredit,
        handleConfirmCheck,
        handleConfirmCredit,
        setOtherMount,
        inputChange,
        handleChange,
        handleBlur,
        handleCalculator,
        // eslint-disable-next-line react/prop-types
        /* isSelected, */
        // eslint-disable-next-line react/prop-types
        /* changeCreditSelect, */
        // eslint-disable-next-line react/prop-types
        //onChangeTextCredit,
    }) => {

    // States and Variables
    const theme = useTheme()
    const navigation = useNavigation()
    return (
        <Container>
            {/** Modal Info */}
            <Modal isVisible={alertInfo.show}>
                <Alert
                    alertInfo={alertInfo}
                    handleAlert={handleAlert}
                />
            </Modal>

            {/** Modal Credits */}
            <Modal isVisible={isVisibleCredit}>
                <Credits
                    data={{ credits, messageErrorCredit }}
                    handleConfirm={handleConfirmCredit}
                    handleCheck={handleCheckCredit}
                    handleClose={handleCloseCredit}
                />
            </Modal>

            {/** Modal Terms */}
            <Modal isVisible={isVisibleTerm} marginVertical={mq(20)}>
                <ModalTerms
                    data={{ terCon, terConF, loadTerms }}
                    handleConfirm={handleConfirmCheck}
                    handleCheck={handleCheck}
                />
            </Modal>

            {/** Modal Cards */}
            <Modal isVisible={isVisibleCard}>
                <Cards
                    data={{ cards, errorCards, messageErrorCard }}
                    handleConfirm={handleConfirmCard}
                    handleClose={handleCloseCard}
                    onChange={onChangeCard}
                    handleIcon={handleIcon}
                />
            </Modal>

            <ScrollView showsVerticalScrollIndicator={false}>
                <Box>

                    {/** Header */}
                    <HeaderContainer>
                        {/* <Title
                            paddingBottom='0'
                            size={mq(22)}
                            fontFamily='PFontBold'
                        >
                            Libre Inversión
                        </Title> */}
                        <Title textAlign='center'>Solicita tu crédito en 15 minutos.</Title>
                    </HeaderContainer>

                    {!!values.ctCatalogue &&
                        <HeadCard style={ShadowBox}>
                            <ButtonNew
                                onPress={() => navigation.navigate('Credits')}
                            >
                                <BoxIcon
                                    testID='jeocsysGUPX7rYlfopAx'
                                    height={mq(40)}
                                    width={mq(40)}
                                    bgColor={`${theme.PColor}05`}
                                    border
                                    style={{ marginTop: mq(15) }}
                                >
                                    <AntDesign
                                        name='plus'
                                        size={mq(30)}
                                        color={theme.PColor}
                                    />
                                </BoxIcon>
                                <Title color={theme.SFColor} style={{ marginTop: mq(5) }}>Solicitar</Title>
                            </ButtonNew>
                            <BoxDescription style={{ flex: 1 }}>
                                <Title
                                    paddingBottom={mq(5)}
                                    size={mq(15)}
                                    fontFamily='PFontBold'
                                    color={theme.PColor}
                                >
                                    Catálogo de Productos
                                </Title>
                                <Title
                                    textAlign='center'
                                    paddingBottom='0'
                                    size={mq(11)}
                                >
                                    Selecciona un producto del catálogo.
                                </Title>

                                <BoxInfo mrg={`${mq(8)}px 0 0 0`}>
                                    <Title
                                        paddingLeft={mq(15)}
                                        paddingBottom={mq(5)}
                                        size={mq(15)}
                                        fontFamily='PFontBold'
                                        color={theme.PColor}
                                    >
                                        $0
                                    </Title>
                                    <Button bgColor={theme.BGColor}>
                                        <Title paddingLeft={mq(8)} size={mq(12)} color={theme.SFColor} paddingBottom='0'>ver detalle</Title>
                                        <MaterialIcons name="keyboard-arrow-right" size={mq(16)} color={theme.SFColor} />
                                    </Button>
                                </BoxInfo>
                            </BoxDescription>
                        </HeadCard>
                    }
                    <Body style={[ShadowBox]}>
                        {loading ? <CreditRequestS />
                            :
                            <>
                                {/* <Label>Seleccionar Línea de Crédito</Label> */}
                                <SelectHook
                                    title='Línea de Crédito*'
                                    name='ctId'
                                    nameAtt='ctName'
                                    data={creditTypes}
                                    value={values.ctId}
                                    error={errors.ctId}
                                    handleSelect={handleSelect}
                                    titleModal='Seleccionar Línea de Crédito'
                                    testID='7c3aae51-38a3-4d74-9090-ace41c8ff02d'
                                />
                                {loadCT ? <CreditRequestInfoS /> : <>
                                    <Text style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Label>¿Cuánto dinero necesitas?</Label>
                                        <ButtonMount onPress={setOtherMount}>
                                            <Text>
                                                ¿Otro Monto?
                                            </Text>
                                        </ButtonMount>
                                    </Text>
                                    {!otherMount ? <ViewSlider onChangeMoney={onChangeMoney} theme={theme} values={values} handleCalculator={handleCalculator}/> : <ViewInput onChangeMoney={inputChange} theme={theme} values={values} handleCalculator={handleCalculator} validateChangeValue={validateChangeValue} />}
                                    <BoxInfo style={[ShadowBox]} mrg={`${mq(20)}px 0`}>
                                        <Center>
                                            <TextFooter>Mínimo</TextFooter>
                                            <TextFooter>$   {values?.errorMessage ? 0 : numberFormat(values.ctMinCap >= 0 ? values.ctMinCap : 250000)}</TextFooter>
                                        </Center>
                                        <Center>
                                            <TextFooter>Máximo</TextFooter>
                                            <TextFooter>$ {values?.errorMessage ? 0 : numberFormat(values.ctMaxCap >= 0 ? values.ctMaxCap : 1000000)}</TextFooter>
                                        </Center>
                                    </BoxInfo>
                                    {values.ctModule !== 1 ? <>
                                        <Label>{`¿A cuántos ${values?.ctType === 2 ? 'Semanas' : 'Meses'}?`}</Label>
                                        <BoxInput>
                                            <Label color={theme.SFSColor} fFamily='PFontMedium' size={mq(13)}>{values.mDefVal >= 0 ? values.mDefVal : 4} {values?.ctType === 2 ? 'Semanas' : 'Meses'}</Label>
                                        </BoxInput>
                                        <Slider
                                            onSlidingComplete={e => handleCalculator({ months: e, money: values?.vcDefVal })}
                                            step={1}
                                            style={styles.slider}
                                            value={values.mDefVal || 4}
                                            onValueChange={onChangeMonth}
                                            thumbTintColor={theme.PColor}
                                            maximumValue={values.mMaxVal || 12}
                                            minimumTrackTintColor={theme.PColor}
                                            maximumTrackTintColor={theme.SFVColor}
                                            testID='4ce633ed-9559-4b90-a946-aa68b140350d'
                                            minimumValue={values.mMinVal >= 0 ? values.mMinVal : 1}
                                        />
                                        <BoxInfo style={[ShadowBox]} mrg={`${mq(20)}px 0`}>
                                            <Center>
                                                <TextFooter>Mínimo</TextFooter>
                                                <TextFooter>{values.mMinVal >= 0 ? values.mMinVal : 1}</TextFooter>
                                            </Center>
                                            <Center>
                                                <TextFooter>Máximo</TextFooter>
                                                <TextFooter>{values.mMaxVal >= 0 ? values.mMaxVal : 12}</TextFooter>
                                            </Center>
                                        </BoxInfo>
                                    </> : <>
                                        <Label color={theme.PColor}>Plazo: 1 Cuota - Fecha de Pago: {values.datCou}.</Label>
                                        <SelectHook
                                            data={values.datPays}
                                            error={errors.datPay}
                                            value={values.datPay}
                                            name='name'
                                            nameAtt='name'
                                            titleModal='Seleccionar Fecha de Pago'
                                            handleSelect={handlePay}
                                        />
                                    </>}
                                    <Center style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                        {/* <Center>
                                            <CheckCredit>¿Este crédito aplica convenio?</CheckCredit>
                                            <CheckBox
                                                style={{ marginTop: '5px' }}
                                                type='checkbox'
                                                value={isSelected}
                                                onChange={changeCreditSelect}
                                            />
                                        </Center> */}
                                        <Center>
                                            <Label>Cuota estimada:</Label>
                                            { !otherMount ?
                                                <TextFee>{numberFormat(Math.round(calculator?.CalculatorCredit?.valCou || 0))}</TextFee>
                                                : <CustomViewInput
                                                    onChangeMoney={inputChange}
                                                    name='totalFee'
                                                    theme={theme}
                                                    value={values?.totalFee || ''}
                                                    numeric
                                                    format
                                                    customOnBlur={() => {
                                                        handleCalculator({ months: values?.mDefVal, totalFee: values?.totalFee })
                                                    }}
                                                />}
                                        </Center>
                                    </Center>
                                    {/* <CheckInputBox>
                                        <CheckInputBox>
                                            {isSelected ?
                                                <InputHook
                                                    name='inputCreditAgreement'
                                                    title='Answer'
                                                    editable
                                                    value={values?.inputCreditAgreement}
                                                    onChangeText={onChangeTextCredit}
                                                />
                                                :
                                                <CheckInputBox />
                                            }
                                        </CheckInputBox>
                                    </CheckInputBox> */}

                                    {/* {(values.statusBond || values.statusLife)
                                        && <BoxInfo justify='center'>
                                            <Label color={theme.SFSColor} fFamily='PFontMedium' align='center'>
                                                La cuota estimada, se ajusta por {values.statusBond && 'calificación'}{(values.statusBond && values.statusLife) && ' y '}{values.statusLife && 'seguro de vida'}.
                                            </Label>
                                        </BoxInfo>
                                    } */}
                                    {(values.typeFormatCredit?.tfcValCreBef === 1 || values.typeFormatCredit?.tfcRatBef === 1 || values.typeFormatCredit?.tfcAttBef === 1 || values.typeFormatCredit?.tfcRepPlaBef === 1) &&
                                        <BoxInfo justify='center'>
                                            <Label color={theme.SFSColor} fFamily='PFontMedium' align='center'>
                                                {values.typeFormatCredit?.tfcValCreBef === 1 && <Label color={theme.SFSColor} fFamily='PFontMedium'>El valor del crédito: {<Label family='PFontBold'>$ {numberFormat(Math.round(calculator?.CalculatorCredit?.valCre))}</Label>}, </Label>}
                                                {values.typeFormatCredit?.tfcRatBef === 1 && <Label color={theme.SFSColor} fFamily='PFontMedium'>La tasa de interés mensual es de: {<Label family='PFontBold'>{(calculator?.CalculatorCredit?.rate || 0).toFixed(2)}%</Label>}, </Label>}
                                                {(values.typeFormatCredit?.tfcAttBef === 1 || values.typeFormatCredit?.tfcRepPlaBef === 1) && <ButtonNew onPress={handleDetailModal} width='100%'><Label fFamily='PFontBold'>Ver mas detalles.</Label></ButtonNew>}
                                            </Label>
                                        </BoxInfo>
                                    }

                                    {companies?.length > 0 &&
                                        <BoxContainer >
                                            {/* <TextFooter>Selecciona la pagaduría</TextFooter> */}
                                            <SelectHook
                                                title='Seleccione la pagaduría'
                                                name='cId'
                                                nameAtt='cBusNam'
                                                data={companies.map(x => { return { ...x, cId: x.cId, cBusNam: x.cBusNam } })}
                                                value={valuesCode?.cId || 0}
                                                handleSelect={handleChange}
                                                // error={valuesCodeError.cId}
                                            />
                                        </BoxContainer>
                                    }

                                    <BoxContainer >

                                        <TextFooter>¿Conoces el código del vendedor?</TextFooter>
                                        <SelectHook
                                            title='Seleccione una opción'
                                            name='idCode'
                                            nameAtt='name'
                                            data={[
                                                { idCode: 1, name: 'SI' },
                                                { idCode: 0, name: 'NO' },
                                            ]}
                                            value={valuesCode?.idCode || 0}
                                            handleSelect={handleChange}
                                        />

                                        {valuesCode?.idCode === 1 &&
                                            <BoxContainerInputHook >
                                                <InputHook
                                                    title='Código de vendedor *'
                                                    value={valuesCode?.sSellerCode}
                                                    showErrorComplete
                                                    name='sSellerCode'
                                                    onBlur={handleBlur}
                                                    onChangeText={handleChange}
                                                    error={valuesCodeError.sSellerCode}
                                                />
                                            </BoxContainerInputHook>
                                        }
                                    </BoxContainer>
                                    <ButtonSubmit
                                        margin={`${mq(20)}px 0`}
                                        loading={loadRequest}
                                        onPress={requestCredit}
                                        disabled={isValid || values?.vcDefVal < values?.ctMinCap || values?.vcDefVal > values?.ctMaxCap || loadCalculator || !calculator?.CalculatorCredit?.valCou }
                                        title='SOLICITA TU CRÉDITO'
                                        testID='565f7f2c-45f7-4f41-ad9e-2fceff6ecb57'
                                    />
                                </>}
                            </>
                        }
                    </Body>
                </Box>
            </ScrollView>
        </Container>
    )
}

CreditsV.propTypes = {
    data: PropTypes.object,
    handlePay: PropTypes.func,
    handleIcon: PropTypes.func,
    handleCheck: PropTypes.func,
    handleAlert: PropTypes.func,
    handleSelect: PropTypes.func,
    onChangeCard: PropTypes.func,
    requestCredit: PropTypes.func,
    onChangeMoney: PropTypes.func,
    onChangeMonth: PropTypes.func,
    handleCloseCard: PropTypes.func,
    handleCloseCredit: PropTypes.func,
    handleCheckCredit: PropTypes.func,
    handleConfirmCard: PropTypes.func,
    handleDetailModal: PropTypes.func,
    handleConfirmCheck: PropTypes.func,
    handleConfirmCredit: PropTypes.func,
    validateChangeValue: PropTypes.bool,
    setOtherMount: PropTypes.func,
    inputChange: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleCalculator: PropTypes.func
}