import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { View, Text, Platform } from 'react-native';
import { CREATE_ENROLLMENT_MILIO } from './queries';
import { brandUrls } from '../../lib/vars';

export const Milio = ({ creditParams, payroll, dataUser }) => {

    const [enrollment] = useMutation(CREATE_ENROLLMENT_MILIO)
    const credit = {
        ...creditParams,
        pcId: payroll?.pcId,
        pcIdeNum: payroll?.pcIdeNum,
        hashCirenio: null
    }
    const infoCredit = encodeURIComponent(JSON.stringify(credit));
    const brans = brandUrls();

    useEffect(() => {
        const scriptElement = document.createElement('script');
        scriptElement.src = 'https://cdn-sandbox.milio.com.co/sdk-checkin/sdk-milio-widget.js';
        scriptElement.async = true;

        document.body.appendChild(scriptElement);

        return () => {
            document.body.removeChild(scriptElement);
        };
    }, []);

    useEffect(async () => {
        const data = {
            name: `${dataUser?.payrollStaff?.psFirNam} ${dataUser?.payrollStaff?.psSecNam}`,
            lastName: `${dataUser?.payrollStaff?.psFirLas} ${dataUser?.payrollStaff?.psSecLas}`,
            typeDocumentId: 1,
            document: dataUser?.pcIdeNum,
            email: dataUser?.payrollStaff?.psEmail,
            webhook: `${brans?.bk}webhook/milio?operation=${infoCredit}`,
        }

        await enrollment({
            variables: {
                input: data,
            }
        }).then(response => {
            setTimeout(() => {
                window.openMilioDialog(response?.data?.createEnrollmentMilio?.key);
            }, 1000)
        }, []).catch(error => {
            console.log(error)
        })

    }, [])

    if (Platform.OS === 'web') {
        return (
            <>
                <div className='Milio'>
                    <Text>Hola</Text>
                </div>
            </>
        );
    } else {
        return (
            <View>
                <Text>El contenido web no está disponible en esta plataforma.</Text>
            </View>
        );
    }
};

Milio.propTypes = {
    dataUser: PropTypes.object,
    creditParams: PropTypes.object,
    payroll: PropTypes.object,
}