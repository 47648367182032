import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { PersonalDataRegisterV } from '../../components/CreditEditData/PersonalDataRegister'
import { ONE_CREDIT_PAYROLL_COMPANY } from '../../pages/CreditEditData/queries'
import { filterKeyObject, updateCache, validationErrors } from '../../validation'
import { isLoggedVar } from '../../apollo/cache'
import { EDIT_CREDIT_PAYROLL_STAFF, EDIT_CREDIT_PAYROLL_COMPANY } from './queries'

export const PersonalDataRegisterC = ({ dataUser, banner, handleNext, format, refScroll }) => {
    const [values, setValues] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errors, setErrors] = useState({})
    const [CreditPayrollStaffMutation, { loading: loadCPS }] = useMutation(EDIT_CREDIT_PAYROLL_STAFF)
    const [CreditPayrollCompanyMutation, { loading: loadCPC }] = useMutation(EDIT_CREDIT_PAYROLL_COMPANY)

    //Actualiza la información de un usuario
    useEffect(() => {
        setValues({
            cpcId: dataUser?.cpcId,
            cpcDatExp: dataUser?.cpcDatExp,
            cName: dataUser?.cpcDomExp,
            creditPayrollStaff: filterKeyObject(dataUser?.creditPayrollStaff, ['__typename'])
        })
        setErrorForm({
            cpcDatExp: (format?.cpfDatExpDoc && !dataUser?.cpcDatExp),
            cName: (format?.cpfDomExpDoc && !dataUser?.cpcDomExp),
            cpsDatBir: (format?.cpfDatBir && !dataUser?.creditPayrollStaff?.cpsDatBir),
            cpsFirNam: (format?.cpfFirNam && !dataUser?.creditPayrollStaff?.cpsFirNam),
            cpsFirLas: (format?.cpfFirLas && !dataUser?.creditPayrollStaff?.cpsFirLas),
            gId: (format?.cpfGender && !dataUser?.creditPayrollStaff?.gId),
            cbId: (format?.cpfContactBy && !dataUser?.creditPayrollStaff?.cbId),
            cId: (format?.cpfLocBir && !dataUser?.creditPayrollStaff?.cId)
        })
    }, [dataUser])

    // Cambio en los valores
    const handleChange = (e, type) => {
        if (type === 1) setValues({ ...values, creditPayrollStaff: { ...values.creditPayrollStaff, [e.name]: e.value } })
        else setValues({ ...values, [e.name]: e.value })
        setErrorForm({ ...errorForm, [e.name]: e.error })
        setErrors({ ...errors, [e.name]: e.error })
    }

    const handleSubmit = async () => {
        setErrors(errorForm)
        /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) return isLoggedVar({ ...isLoggedVar(), message: 'Por favor rellene todo los campos' })

        const { data: dataCPS, error: errorCPS } = await CreditPayrollStaffMutation({
            variables: { input: filterKeyObject(values.creditPayrollStaff, ['cpcDatExp', 'cName']) }, update(cache, { data: { setOrUpdateCreditPayrollStaff } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollStaff, variables: { state: [0, 1, 2, 3] } })
            }
        }).catch(e => ({ error: e }))

        const { data: dataCPC, error: errorCPC } = await CreditPayrollCompanyMutation({
            variables: { cpcId: values.cpcId, input: { cpcDatExp: values.cpcDatExp, cpcDomExp: values.cName } }, update(cache, { data: { setOrUpdateCreditPayrollCompany } }) {
                updateCache({ cache, query: ONE_CREDIT_PAYROLL_COMPANY, nameFun: 'getCreditPayrollCompanyById', dataNew: setOrUpdateCreditPayrollCompany, variables: { state: [0, 1, 2, 3] } })
            }
        }).catch(e => ({ error: e }))

        // verifica si hay error
        if (errorCPS || errorCPC) return isLoggedVar({ ...isLoggedVar(), message: (errorCPS || errorCPC).message })

        /** resultado */
        if (dataCPS?.setOrUpdateCreditPayrollStaff && dataCPC?.setOrUpdateCreditPayrollCompany) {
            refScroll?.current && refScroll?.current.scrollTo({ x: 0, y: 0, animated: true })
            setErrorForm({})
            handleNext(1.5)
        }
    }
    return (
        <PersonalDataRegisterV
            data={{ values: { ...values, tiId: dataUser?.typeIdentity?.tiId, cpcIdeNum: dataUser?.cpcIdeNum }, errors, format, loadCPS, loadCPC, banner }}
            handleBlur={e => setErrorForm({ ...errorForm, [e.name]: e.error })}
            handleChange={handleChange}
            handleChangeStaff={handleChange}
            handleSubmit={handleSubmit}
        />
    )
}

PersonalDataRegisterC.propTypes = {
    dataUser: PropTypes.object,
    banner: PropTypes.node,
    handleNext: PropTypes.func,
    format: PropTypes.object,
    refScroll: PropTypes.object
}