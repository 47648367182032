import { gql } from '@apollo/client'

export const ALL_GROUP_CREDIT_TYPES = gql`
    query groupCreditTypes($gcId: ID) {
        groupCreditTypes(gcId: $gcId, order: [["gctOrder", "ASC"]], state: [0, 1, 2]) {
            gctId
            ctId
            gctOrder
            gctType
            gctIntFee
            gctSeeValReq
            gctSeeValCre
            gctSeeValFee
            gctSeeRat
            gctSeeMon
            gctSeeAtt
            gctState
            creditType {
                ctId
                ctConsecutive
                ctName
                ctModule
                ctType
                ctMultiple
                mId
                month {
                    mValue
                }
                vId
                vat {
                    vPor
                }
                cdId
                codebtor {
                    cdName
                }
                ctNumCoDebtors
                ufId
                userFormat {
                    ufName
                    ufTipIde
                    ufIdeNum
                    ufDatExpDoc
                    ufDomExpDoc
                    ufFirNam
                    ufSecNam
                    ufFirLas
                    ufSecLas
                    ufDatBir
                    ufPhoMob
                    ufPhoPer
                    ufEmail
                    ufGender
                    ufContactBy
                    ufLocation
                    ufAcaLev
                    ufMarSta
                    ufTypHou
                    ufDependents
                    ufPosition
                    ufOthInc
                    ufFinExp
                    ufPerExp
                    ufRefFam
                    ufRefPar
                    ufRefStu
                    ufRelationship
                    ufPerDoc
                    ufFroDoc
                    ufReaDoc
                    ufSerDoc
                    ufBanDoc
                    ufConDoc
                    ufPayRemDoc
                    ufOPS
                    ufComActDoc
                    ufBudRecDoc
                    ufBudCerDoc
                    ufRut
                    ufComChamber
                    ufInstitutionPic
                    ufBankStatement
                    ufFinancialStatements
                    ufIncomeTaxReturn
                    ufBoardDirectors
                    ufTradeBalance
                    ufRealEstateRegistration
                    ufVehicleOwnershipCard
                    ufCommercialReference
                    ufBankReference
                    ufIncomeWithholdings
                    ufCadastreAndValuation
                    ufCommercialAppraisal
                    ufWorkCertificate
                    ufOtherInformation
                    ufCirenio
                    ufEconomyActivities
                }
                ctAdjustable
                ctReTanking
                ctTanPer
                ctOptPay
                ctOptPer
                ctMaxCap
                ctCard
                ctCarPer
                ctGroup
                ctState
                valueCredit {
                    vcId
                    vcMinVal
                    vcMaxVal
                    vcDefVal
                    mId
                    month {
                        mValue
                    }
                    vcState
                }
                rId
                rate {
                    rPor
                }
                creditTypeAttributes {
                    ctaId
                    taId
                    ctaState
                    typeAttribute(order: [["taPriority", "ASC"]]) {
                        taId
                        taName
                        taType
                        taFormula
                        taMinimum
                        taValue
                        taValue2
                        taPor
                        tbId
                        typeBond {
                            tbId
                            bonds {
                                bPor
                            }
                        }
                        tlmId
                        typeLifeMillion {
                            tlmId
                            tlmFormula
                            lifeMillions {
                                lmSince
                                lmUntil
                                lmValue
                                lmValueTwo
                                lmPor
                            }
                        }
                        sId
                        salary {
                            sValue
                        }
                        taRates
                        taPriority
                        taVat
                    }
                }
                requestCredits {
                    rcId
                    dcId
                    documentContract {
                        dcName
                        dcDocument
                        dcState
                    }
                    rcGroup
                    rcPriority
                    rcState
                }
            }
        }
    }
`