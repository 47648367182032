import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Entypo, FontAwesome5, FontAwesome, AntDesign } from '@expo/vector-icons'
import Constants from 'expo-constants'
import * as FileSystem from 'expo-file-system'
import { useLazyQuery, useQuery } from '@apollo/client'
import { RequestCreditDataV } from '../../components/RequestCreditData'
import { PersonalDataRegisterC } from '../../containers/PersonalDataRegister'
import { RelationDataRegisterC } from '../../containers/RelationDataRegister'
import { LocationDataRegisterC } from '../../containers/LocationDataRegister'
import { EconomicDataRegisterC } from '../../containers/EconomicDataRegister'
import { ReferenceDataRegisterC } from '../../containers/ReferenceDataRegister'
import { DocumentDataRegisterC } from '../../containers/DocumentDataRegister'
import { CoDebtorDataRegisterC } from '../../containers/CoDebtorDataRegister'
import { OPSDataRegisterC } from '../../containers/OPSDataRegister'
import { LifeInsuranceC } from '../../containers/LifeInsurance'
import theme from '../../../ignore/Constants'
import { base64ToArrayBuffer, extFile, wp } from '../../validation'
import { UserIdent, Home } from '../../../assets/icons'
import { BannerInfoData } from '../../components/BannerInfoData'
import { FormCreditS } from '../../skeleton/FormCreditS'
import { CIRENIO_PARAMETERS, USER_PARAMETERS, USE_ONE_PAYROLL_COMPANY } from './queries'
import { isLoggedVar } from '../../apollo/cache'
import { OtherInformationC } from '../../containers/OtherInformation'
import { Cirenio } from '../../containers/Cirenio'
import { PayZenFormLyra } from '../../containers/Lyra/lyra'
import { Milio } from '../../containers/Milio'

export const RequestCreditData = ({ navigation, route }) => {
    const [typeInfo, setTypeInfo] = useState(1)
    const [isVisibleOption, setIsVisibleOption] = useState(false)
    const [optionCamera, setOptionCamera] = useState({})
    const [dataUser, setDataUser] = useState({})
    const [documents, setDocuments] = useState({})
    const [infoBanner, setInfoBanner] = useState({
        title: 'Cuéntanos sobre ti',
        icon: <Entypo name='user' size={wp(7)} color={theme.backgroundC} />,
        titleModal: 'Cuéntanos sobre ti',
        iconModal: <UserIdent width={wp(50)} height={wp(50)} color={theme.backgroundC} />,
        steps: [
            { text: 'En esta sección debes  ingresar la siguiente información personal.' },
            { text: '1. Nombres y apellidos.' },
            { text: '2. Fecha de nacimiento.' },
            { text: '3. Número de teléfono.' },
            { text: '4. Correo electrónico.' },
            { text: '5. Género.' }
        ]
    })
    const refScroll = useRef()
    const [getOnePayroll, { data, loading, error, called, refetch: refetchPayroll }] = useLazyQuery(USE_ONE_PAYROLL_COMPANY, { fetchPolicy: 'cache-and-network' })
    const { data: userParameters } = useQuery(USER_PARAMETERS, {
        fetchPolicy: 'network-only',
    });
    const { data: cirenio } = useQuery(CIRENIO_PARAMETERS, {
        fetchPolicy: 'network-only',
    });

    const format = route?.params?.userFormat || {}
    const ctLyra = route?.params?.ctLyra || 0
    const ctCirenio = route?.params?.ctCirenio || 0
    const ctMilio = route?.params?.ctMilio || 0
    const coDebtor = { quantity: route?.params?.number || 0, format: route?.params?.codebtor || {} }

    // Activa un evento para buscar el perfil del usuario
    useEffect(() => {
        // crea un evento para consultar el perfil del usuario
        const unsubscribe = navigation.addListener('focus', () => getOnePayroll({ variables: { state: [0, 1, 2, 3] } }))

        // elimina el evento
        return unsubscribe
    }, [navigation, getOnePayroll])

    useEffect(() => {
        const res = data?.getPayrollCompanyById
        if (called && res && !(route.params?.event && route.params?.typeA)) setDataUser(res)

        if (error) {
            isLoggedVar({ ...isLoggedVar(), message: error })
            navigation.navigate('Home')
        }
    }, [data, called, error, route.params])

    // acción de capturar foto
    const onPicture = async ({ event, type, typeA, nameFile }) => {
        const ext = nameFile ? extFile(nameFile) : 'jpeg'

        let file, base64
        if (Constants.platform.web) {
            base64 = event
            file = base64ToArrayBuffer(event.substring(event.indexOf('base64,') + 7, event.length))
        } else {
            base64 = await FileSystem.readAsStringAsync(event, { encoding: FileSystem.EncodingType.Base64 })
            file = base64ToArrayBuffer(base64)
            base64 = event
        }
        setDocuments({ name: type, nameA: typeA, file, ext, base64 })
    }

    useEffect(() => {
        if (route.params?.event) onPicture({ event: route.params.event, type: route.params.type, typeA: route.params.typeA, nameFile: route.params.nameFile })
        else {
            setTypeInfo(1)
            setDocuments({})
            setInfoBanner(typeInfo === 1 ? infoBanner : {})
        }
    }, [route.params])

    const handleStepDocuments = () => {
        if (
            userParameters?.getUserParametersById?.lifeInsurance !== 1 && format?.ufLifeInsurance !== 1 &&
            format?.ufOtherInformation !== 1
        ) {
            return 5;
        }
        if (
            ((userParameters?.getUserParametersById?.lifeInsurance !== 1 || format?.ufLifeInsurance !== 1) &&
                format?.ufOtherInformation === 1) ||
            (userParameters?.getUserParametersById?.lifeInsurance === 1 && format?.ufLifeInsurance === 1 &&
                format?.ufOtherInformation !== 1)
        ) {
            return 5;
        }
        return 6;
    };

    const handleStepCodebtor = () => {
        if (
            userParameters?.getUserParametersById?.lifeInsurance !== 1 && format?.ufLifeInsurance !== 1 &&
            format?.ufOtherInformation !== 1
        ) {
            return 6;
        }
        if (
            ((userParameters?.getUserParametersById?.lifeInsurance !== 1 || format?.ufLifeInsurance !== 1) &&
                format?.ufOtherInformation === 1) ||
            (userParameters?.getUserParametersById?.lifeInsurance === 1 && format?.ufLifeInsurance === 1 &&
                format?.ufOtherInformation !== 1)
        ) {
            return 6;
        }
        return 7;
    };

    console.log(dataUser)
    useEffect(() => {
        if (typeInfo === 1) {
            setInfoBanner({
                title: 'Cuéntanos sobre ti',
                icon: <Entypo name='user' size={wp(7)} color={theme.backgroundC} />,
                titleModal: 'Cuéntanos sobre ti',
                iconModal: <UserIdent width={wp(50)} height={wp(50)} color={theme.backgroundC} />,
                steps: [
                    { text: 'En esta sección debes  ingresar la siguiente información personal.' },
                    { text: '1. Nombres y apellidos.' },
                    { text: '2. Fecha de nacimiento.' },
                    { text: '3. Número de teléfono.' },
                    { text: '4. Correo electrónico.' },
                    { text: '5. Género.' }
                ]
            })
        } else if (typeInfo === 1.5) {
            if (format?.ufRelationship) {
                setInfoBanner({
                    title: 'Información para el seguro',
                    icon: <Entypo name='user' size={wp(7)} color={theme.backgroundC} />,
                    titleModal: 'Información para el seguro',
                    iconModal: <UserIdent width={wp(50)} height={wp(50)} color={theme.backgroundC} />,
                    steps: [
                        { text: 'En esta sección debes  ingresar la siguiente información personal.' },
                        { text: '1. Tipo de Parentesco.' },
                        { text: '2. Nombre del Pariente.' },
                        { text: '3. Porcentaje del beneficiario.' }

                    ]
                })
            } else setTypeInfo(2)
        } else if (typeInfo === 2) {
            if (format?.ufLocation) {
                setInfoBanner({
                    title: 'Información de residencia',
                    icon: <Home width={wp(8)} height={wp(8)} color={theme.backgroundC} />,
                    titleModal: 'Información de residencia',
                    iconModal: <Home width={wp(50)} height={wp(50)} color={theme.backgroundC} />,
                    steps: [
                        { text: 'En esta sección deberás ingresar la siguiente información de residencia.' },
                        { text: '1. Ciudad.' },
                        { text: '2. Dirección específica.' },
                        { text: '3. Información residencial (opcional).' }
                    ]
                })
            } else setTypeInfo(3)
        } else if (typeInfo === 3) {
            if (format?.ufTypHou || format?.ufDependents || format?.ufAcaLev || format?.ufMarSta || format?.ufOthInc || format?.ufFinExp || format?.ufPerExp) {
                setInfoBanner({
                    title: 'Información general',
                    icon: <FontAwesome5 name='comment-dollar' size={wp(7)} color={theme.backgroundC} />,
                    titleModal: 'Información general',
                    iconModal: <FontAwesome5 name='comment-dollar' size={wp(50)} color={theme.backgroundC} />,
                    steps: [
                        { text: 'En esta sección deberás ingresar la siguiente información.' },
                        { text: '1. Tipo de vivienda.' },
                        { text: '2. Personas a cargo.' },
                        { text: '3. Nivel académico.' },
                        { text: '4. Estado civil.' },
                        { text: '5. Ingresos mensuales.' },
                        { text: '7. Gastos financieros mensuales.' },
                        { text: '8. Gastos personales mensuales.' },
                        { text: '9. Actividad principal.' },
                        { text: '10. Entidad Aseguradora.' },
                    ]
                })
            } else setTypeInfo(3.5)
        } else if (typeInfo === 3.5) {
            if (format?.ufOPS) {
                setInfoBanner({
                    title: 'Información OPS',
                    icon: <FontAwesome5 name='comment-dollar' size={wp(7)} color={theme.backgroundC} />,
                    titleModal: 'Información OPS',
                    iconModal: <FontAwesome5 name='comment-dollar' size={wp(50)} color={theme.backgroundC} />,
                    steps: [
                        { text: 'En esta sección deberás ingresar la siguiente información.' },
                        { text: '1. Contrato de la OPS.' },
                        { text: '2. Objeto.' },
                        { text: '3. Valor del contrato.' },
                        { text: '4. Vigencia desde y hasta.' }
                    ]
                })
            } else setTypeInfo(4)
        } else if (typeInfo === 4) {
            if (format?.ufRefPar || format?.ufRefFam || format?.ufRefEst) {
                setInfoBanner({
                    title: 'Información de Referencias',
                    icon: <FontAwesome name='users' size={wp(8)} color={theme.backgroundC} />,
                    titleModal: 'Información de Referencias',
                    iconModal: <FontAwesome name='users' size={wp(50)} color={theme.backgroundC} />,
                    steps: [
                        { text: 'En esta sección deberás ingresar la siguiente información.' },
                        { text: 'Referencias familiar, personal y estudiantil.' },
                        { text: '1. Nombres.' },
                        { text: '2. Apellidos.' },
                        { text: '3. Ciudad.' },
                        { text: '4. Dirección.' }
                    ]
                })
            } else setTypeInfo(4.5)
        } else if (userParameters?.getUserParametersById?.lifeInsurance === 1 && format?.ufLifeInsurance === 1 && typeInfo === 4.5) {
            if (format?.ufRefPar || format?.ufRefFam || format?.ufRefEst) {
                setInfoBanner({
                    title: 'Seguro de vida',
                    icon: <FontAwesome name='users' size={wp(8)} color={theme.backgroundC} />,
                    titleModal: 'Seguro de vida',
                    iconModal: <FontAwesome name='users' size={wp(50)} color={theme.backgroundC} />,
                    steps: [
                        { text: 'En esta sección deberás ingresar la siguiente información.' },
                        { text: '1. Cancer.' },
                        { text: '2. Sida.' },
                        { text: '3. Accidente cerebrovascular.' },
                        { text: '4. Hipertensión arterial.' },
                        { text: '5. Diabetes.' },
                        { text: '6. Enfermedades de las arterias coronarias.' },
                        { text: '7. Infarto.' },
                        { text: '8. Tiene alguna limitación física o mental congénita o adquirida.' },
                        { text: '9. Le han diagnosticado o tratado alguna enfermedad diferente a las citadas arriba.' },
                        { text: '10. Fecha de diagnóstico.' },
                        { text: '11. Tratamiento.' },
                        { text: '12. Estado actual.' },
                        { text: '13. Está embarazada.' },
                        { text: '14. Es primigestante.' },
                        { text: '15. Tiempo de gestación.' },
                        { text: '16. ¿Eres pensionado por invalidez?.' },
                        { text: '17. ¿Consume bebidas alcohólicas mas de 3 veces por semana o alguna sustancia psicoactiva?.' },
                        { text: '18. Nombre de la Enfermedad.' },
                        { text: '19. Secuelas o condiciones.' },
                        { text: '20. Observaciones o comentarios adicionales.' },
                        { text: '21. Tratamiento Actual.' },
                        { text: '22. Tratamientos médicos o cirugías realizadas.' },
                    ]
                })
            } else setTypeInfo(4.5)
        } else if ((format?.ufOtherInformation === 1 && typeInfo === 4.5) || (userParameters?.getUserParametersById?.lifeInsurance === 1 && format?.ufLifeInsurance === 1 && format?.ufOtherInformation === 1 && typeInfo === 5)) {
            if (format?.ufOtherInformation === 1) {
                setInfoBanner({
                    title: 'Inf. fianza, seguros del estado o colpensiones',
                    icon: <FontAwesome name='users' size={wp(8)} color={theme.backgroundC} />,
                    titleModal: 'Inf. fianza, seguros del estado o colpensiones',
                    iconModal: <FontAwesome name='users' size={wp(50)} color={theme.backgroundC} />,
                    steps: [
                        { text: 'En esta sección deberás ingresar la siguiente información.' },
                        { text: '1. Número de afiliación.' },
                        { text: '2. Cómo persona natural, posee usted nacionalidad estadounidense?.' },
                        { text: '3. ¿Usted transfiere o da instrucciones permanentes para transferir fondos a una cuenta de estados unidos?.' },
                        { text: '4. ¿Del 50% o más de sus activos totales y/ o los ingresos, provienen de activos de inversión en estados unidos? .' },
                        { text: '5. ¿Usted posee tarjeta de residencia americana (Green Card)?.' },
                        { text: '6. ¿Nació en estados unidos?.' },
                        { text: '7. ¿Vivió las 2/3 partes del año anterior en estados unidos?.' },
                        { text: '8. ¿Realiza transacciones en moneda extranjera?.' },
                        { text: '9. ¿Realiza ejerce las siguientes actividades?.' },
                        { text: '10. ¿Es sujeto de obligaciones tributarias en otro país?.' },
                        { text: '11. Mi actividad comercial, profesión u oficio es lícita y la ejerzo dentro del marco legal, los recursos que poseo no provienen de actividades ilícitas de las contempladas en el Código Penal Colombiano.' },
                        { text: '12. La información que he suministrado en este formato es veraz y verificable, y me obligo a confirmar los datos suministrados y anualmente actualizándola conforme a los procedimientos que para tal efecto tenga establecidos la Cooperativa.' },
                        { text: '13. El incumplimiento de la obligación establecida faculta a la Cooperativa para revocar unilateralmente el contrato. Los recursos que se deriven del desarrollo de este contrato no se destinarán a la financiación del terrorismo, grupos terroristas o actividades terroristas.' },
                        { text: '14. Manifiesto que no he sido declarado responsable judicialmente por la comisión de delitos contra la Administración Pública cuya pena sea privativa de la libertad o que afecten el patrimonio del Estado o por delitos relacionados con la pertenencia, promoción o financiación de grupos ilegales, delito de lesa humanidad, narcotráfico en Colombia o en el exterior, soborno transnacional, cohecho (en sus distintas modalidades), tráfico de influencias, colusión, corrupción en el ámbito privado.' },
                        { text: '15. Las declaraciones contenidas en estos documentos son exactas, completas y verídicas en la forma en que aparecen escritas.' },
                        { text: '16. Manifiesto de manera voluntaria haber leído y entendido todo lo contenido en este documento y acepto las responsabilidades civiles, fiscales y penal con respecto a la veracidad y responsabilidad de esta información.' },
                        { text: '17. Solicitud incremento valor asegurado a.' },
                        { text: '18. Valor de prima.' },
                    ]
                })
            } else setTypeInfo(handleStepDocuments())
        } else if (typeInfo === handleStepDocuments()) {
            if (format?.ufPerDoc || format?.ufFroDoc || format?.ufReaDoc || format?.ufSerDoc) {
                setInfoBanner({
                    title: 'Documentos Personales',
                    icon: <AntDesign name='idcard' size={wp(7)} color={theme.backgroundC} />,
                    titleModal: 'Documentos Personales',
                    iconModal: <AntDesign name='idcard' size={wp(50)} color={theme.backgroundC} />,
                    steps: [
                        { text: 'En esta sección deberás tomar fotos y subir los documentos solicitados.' },
                        { text: '1. Cédula frontal.' },
                        { text: '2. Cédula por detrás.' },
                        { text: '3. Selfie con Cédula frontal.' },
                        { text: '4. Servicios Públicos (Luz, Agua o Gas).' }
                    ]
                })
            } else setTypeInfo(handleStepCodebtor())
        } else if (typeInfo === handleStepCodebtor()) {
            if (coDebtor?.quantity) {
                setInfoBanner({
                    title: 'Información de Codeudores',
                    icon: <FontAwesome5 name='user-tie' size={wp(7)} color={theme.backgroundC} />,
                    titleModal: 'Información de Codeudores',
                    iconModal: <FontAwesome5 name='user-tie' size={wp(50)} color={theme.backgroundC} />,
                    steps: [
                        { text: 'En esta sección deberás tomar fotos y subir los documentos solicitados.' },
                        { text: '1. Tipo de identificación.' },
                        { text: '2. Número de documento.' },
                        { text: '3. Nombres y apellidos.' },
                        { text: '4. Fecha de nacimiento.' },
                        { text: '5. Número de teléfono fijo.' },
                        { text: '6. Número de teléfono móvil.' },
                        { text: '7. Número de teléfono.' },
                        { text: '8. Correo electrónico.' },
                        { text: '9. Género.' },
                        { text: '10. Ciudad.' },
                        { text: '11. Dirección específica.' },
                        { text: '12. Información residencial (opcional).' },
                        { text: '13. Nivel académico.' },
                        { text: '14. Estado civil.' },
                        { text: '15. Tipo de vivienda.' },
                        { text: '16. Personas a cargo.' },
                        { text: '17. Nombre de la empresa.' },
                        { text: '18. Cargo de la empresa.' },
                        { text: '19. Tipo de Contrato.' },
                        { text: '20. Salario mensual.' },
                        { text: '21. Ingresos mensuales.' },
                        { text: '22. Gastos financieros mensuales.' },
                        { text: '23. Gastos personales mensuales.' },
                        { text: '24. Selfie con Cédula frontal.' },
                        { text: '25. Cédula frontal.' },
                        { text: '26. Cédula por detrás.' },
                        { text: '27. Servicios Públicos (Luz, Agua o Gas).' },
                        { text: '28. Certificado Bancario.' },
                        { text: '29. Certificado Laboral.' }
                    ]
                })
            } else setTypeInfo(8)
        } else if (typeInfo === 8 && ctMilio === 1) {
            setInfoBanner({
                title: 'Milio',
                icon: <FontAwesome5 name='user-tie' size={wp(7)} color={theme.backgroundC} />,
                titleModal: 'Milio',
                iconModal: <FontAwesome5 name='user-tie' size={wp(50)} color={theme.backgroundC} />,
                steps: [
                    { text: 'En esta sección existe información de Milio.' },
                ]
            })
        } else if (typeInfo === 8 && ctCirenio === 1 && cirenio?.getCirenioParameter) {
            setInfoBanner({
                title: 'Cirenio',
                icon: <FontAwesome5 name='user-tie' size={wp(7)} color={theme.backgroundC} />,
                titleModal: 'Cirenio',
                iconModal: <FontAwesome5 name='user-tie' size={wp(50)} color={theme.backgroundC} />,
                steps: [
                    { text: 'En esta sección existe información de CIRENIO.' },
                ]
            })
        } else if (typeInfo === 9) {
            setInfoBanner({
                title: 'Credenciales de tarjeta débito',
                icon: <AntDesign name='creditcard' size={wp(7)} color={theme.backgroundC} />,
                titleModal: 'Credenciales de tarjeta débito',
                iconModal: <AntDesign name='creditcard' size={wp(50)} color={theme.backgroundC} />,
                steps: [
                    { text: 'Para agilizar el proceso de desembolso, por favor asegúrate de ingresar correctamente las credenciales de tu tarjeta débito de nómina.' },
                    { text: 'Estos datos son necesarios para garantizar un desembolso inmediato de tu crédito.' },
                    { text: 'Además, al proporcionar esta información, aseguras la aprobación para el débito automático en caso de mora con los pagos obligatorios que excedan los 15 días calendario.' },
                ]
            })
        } else {
            setTypeInfo(1)
            setDocuments({})
            setInfoBanner(typeInfo === 1 ? infoBanner : {})
            getOnePayroll({ variables: { state: [0, 1, 2, 3] } })
            navigation.navigate('RequestCredit')
        }
    }, [typeInfo, route.params])

    // Activa la opción de cámara o documento
    const handleButton = (type, typeA) => {
        setOptionCamera({ screen: 'RequestCreditData', type, typeA })
        setIsVisibleOption(!isVisibleOption)
    }

    const creditParams = JSON.parse(localStorage.getItem('infCredit'))
    return (
        <RequestCreditDataV data={{ isVisibleOption, typeInfo, navigation, params: optionCamera }} handleTravel={value => setTypeInfo(value)} handleClose={() => setIsVisibleOption(false)} refs={refScroll}>
            {typeInfo === 1 ?
                ((!data?.getPayrollCompanyById && loading) || !format) ? <FormCreditS /> :
                    <PersonalDataRegisterC
                        dataUser={dataUser}
                        format={format}
                        typeInfo={typeInfo}
                        banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                        handleNext={value => setTypeInfo(value)}
                        navigation={navigation}
                        refScroll={refScroll}
                    />
                :
                typeInfo === 1.5 ?
                    <RelationDataRegisterC
                        dataUser={dataUser}
                        banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                        handleNext={value => setTypeInfo(value)}
                        refScroll={refScroll}
                    />
                    :
                    typeInfo === 2 ?
                        <LocationDataRegisterC
                            dataUser={dataUser}
                            banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                            handleNext={value => setTypeInfo(value)}
                            refScroll={refScroll}
                        />
                        :
                        typeInfo === 3 ?
                            <EconomicDataRegisterC
                                dataUser={dataUser}
                                format={format}
                                banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                                handleNext={value => setTypeInfo(value)}
                                refScroll={refScroll}
                                refetchPayroll={refetchPayroll}
                                userParameters={userParameters?.getUserParametersById}
                            />
                            :
                            typeInfo === 3.5 ?
                                <OPSDataRegisterC
                                    dataUser={dataUser}
                                    banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                                    handleNext={value => setTypeInfo(value)}
                                    refScroll={refScroll}
                                    reTanking={route?.params?.cReTanking}
                                    optPay={route?.params?.cOptPay}
                                    credits={route?.params?.credits}
                                    permission={route?.params?.permission}
                                    payment={route?.params?.payment || 0}
                                    group={route?.params?.group}
                                />
                                :
                                typeInfo === 4 ?
                                    <ReferenceDataRegisterC
                                        dataUser={dataUser}
                                        format={format}
                                        banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                                        handleNext={value => setTypeInfo(value)}
                                        refScroll={refScroll}
                                        userParameters={userParameters?.getUserParametersById}
                                        handleStepDocuments={handleStepDocuments}
                                    />
                                    :
                                    userParameters?.getUserParametersById?.lifeInsurance === 1 && format?.ufLifeInsurance === 1 && typeInfo === 4.5 ?
                                        <LifeInsuranceC
                                            dataUser={dataUser}
                                            format={format}
                                            banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                                            handleNext={value => setTypeInfo(value)}
                                            refScroll={refScroll}
                                        />
                                        :
                                        (((userParameters?.getUserParametersById?.lifeInsurance !== 1 || format?.ufLifeInsurance !== 1) &&
                                            format?.ufOtherInformation === 1) && typeInfo === 4.5) || (userParameters?.getUserParametersById?.lifeInsurance === 1 && format?.ufLifeInsurance === 1 &&
                                                format?.ufOtherInformation === 1) && typeInfo === 5 ?
                                            <OtherInformationC
                                                dataUser={dataUser}
                                                format={format}
                                                userParameters={userParameters}
                                                banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                                                handleNext={value => setTypeInfo(value)}
                                                refScroll={refScroll}
                                                refetchPayroll={refetchPayroll}
                                                handleStepDocuments={handleStepDocuments}
                                            />
                                            :
                                            typeInfo === handleStepDocuments() ?
                                                <DocumentDataRegisterC
                                                    documents={documents}
                                                    dataUser={dataUser}
                                                    banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                                                    handleNext={value => setTypeInfo(value)}
                                                    format={format}
                                                    cirenio={cirenio}
                                                    ctCirenio={ctCirenio}
                                                    ctMilio={ctMilio}
                                                    coDebtor={coDebtor}
                                                    handleButton={handleButton}
                                                    navigation={navigation}
                                                    refScroll={refScroll}
                                                    handleStepCodebtor={handleStepCodebtor}
                                                    creditParams={creditParams}
                                                />
                                                :
                                                typeInfo === handleStepCodebtor() ?
                                                    <CoDebtorDataRegisterC
                                                        documents={documents}
                                                        dataUser={dataUser}
                                                        banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                                                        handleNext={value => setTypeInfo(value)}
                                                        coDebtor={coDebtor}
                                                        format={format}
                                                        cirenio={cirenio}
                                                        ctCirenio={ctCirenio}
                                                        ctMilio={ctMilio}
                                                        handleButton={handleButton}
                                                        navigation={navigation}
                                                        refScroll={refScroll}
                                                    />
                                                    :
                                                    typeInfo === 8 && ctMilio === 1 ?
                                                        <Milio dataUser={dataUser} creditParams={creditParams}
                                                            payroll={data?.getPayrollCompanyById} />
                                                        :
                                                        typeInfo === 8 && ctCirenio === 1 && cirenio?.getCirenioParameter ?
                                                            <Cirenio handleNext={value => setTypeInfo(value)} navigation={navigation} payroll={data?.getPayrollCompanyById} ctLyra={ctLyra} ctCirenio={ctCirenio} />
                                                            :
                                                            typeInfo === 9 && ctLyra === 1 ?
                                                                <PayZenFormLyra
                                                                    creditParams={creditParams}
                                                                    payroll={data?.getPayrollCompanyById}
                                                                    banner={<BannerInfoData title={infoBanner.title} icon={infoBanner.icon} titleModal={infoBanner.titleModal} iconModal={infoBanner.iconModal} steps={infoBanner.steps} />}
                                                                />
                                                                : <></>
            }
        </RequestCreditDataV>
    )
}

RequestCreditData.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object
}